import React from 'react'
import PropTypes from 'prop-types'
import { TYPES_PRESCRIPTION } from '../../../../constants'

import units from '../../../../jsonSuggestions/units_raw.json'
import { useTranslate } from 'react-polyglot'

function toKey (obj, entry) {
  obj[entry.id] = entry
  return obj
}

const UNITS = units.reduce(toKey, {})

/**
 *
 * @param dosage {{value: string, unit: number}}
 * @returns {string}
 */
function createDosageDescription (dosage) {
  return !dosage.value ? null : `${dosage.value} ${UNITS[dosage.unit].field}`
}

/**
 *
 * @param item {BasketManipulated}
 * @return {string}
 */
function createManipulatedName (item) {
  const dosageValues = Object.values(item.dosage)
  if (dosageValues.length === 0) {
    return item.activeSubstances
  } else if (dosageValues.length === dosageValues.filter(a => a.label).length) {
    return dosageValues.map(d => `${d.label} (${createDosageDescription(d)})`).join(' + ')
  }
  return `${item.activeSubstances} ${dosageValues.map(createDosageDescription).join(' + ')}`
}

/**
 *
 * @param item {BasketOthers}
 * @return {string}
 */
function createOthersName (item) {
  return Object.values(item.components).map(d => {
    const dosageDescription = createDosageDescription(d)
    if (dosageDescription) {
      return `${d.text} ${createDosageDescription(d)}`
    }
    return d.text
  }).join(' + ')
}

/**
 *
 * @param item
 * @return {string}
 */
function getNameForItem (item) {
  let description
  switch (item.type) {
    case TYPES_PRESCRIPTION.MEDICINE: {
      const { medicine, requestedGeneric } = item
      const name = (medicine.isGeneric || requestedGeneric) ? medicine.activeComponent : medicine.commercialName
      description = `${name}, ${medicine.dosage}, ${medicine.type.name}`
      break
    }
    case TYPES_PRESCRIPTION.AIR_CHAMBER:
      description = item.airChamberText
      break
    case TYPES_PRESCRIPTION.GLUCOSE:
      // Mobile-App works with item.glucoseText, maybe redux is doing some mapping?
      description = item.glucose.field
      break
    case TYPES_PRESCRIPTION.MANIPULATED:
      description = createManipulatedName(item)
      break
    case TYPES_PRESCRIPTION.OTHERS:
      description = createOthersName(item)
      break
    default:
      description = 'Este tipo de linha é desconhecido'
      break
  }
  return description
}

export const CardItemsInputGroup = React.memo(function CardItems ({items}) {
  const t = useTranslate()
  return items.map(item => {
    return <div key={item.id} className='input-group input-group-lg mb-1 px-5'>
      <input type="text" className="form-control" readOnly value={getNameForItem(item)}/>
      <div className="input-group-append"><span className="input-group-text">{t('units', item.quantity)}</span></div>
    </div>
  })
})

const CardItems = React.memo(function CardItems ({ items }) {
  return items.map(item => {
    return <tr key={item.id}>
      <td>{getNameForItem(item)}</td>
      <td className="d-flex justify-content-end">{item.quantity} un.</td>
    </tr>
  })
})

CardItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      quantity: PropTypes.number
    })).isRequired
}

export default CardItems
