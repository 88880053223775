import { InfarmedSdk, ApiRequestCache } from '@kenetto/infarmed-sdk'

export const SERVER_CHECK_VALUE = process.env.REACT_APP_SERVER_CHECK_VALUE
export const SIGNATURE_SERVER_PATH = process.env.REACT_APP_SIGNATURE_SERVER_PATH
export const SPMS_HASH_PRESCRIPTION = '/pem2/'
export const SIGNATURE_SERVER_CONFIRM_PRESCRIPTION_LOCATION = '/pem2/prescribe'
export const CC_SIGN_LOCATION = '/sign'
export const SERVER_AUTHENTICATION_CERTIFICATES_LOCATION = '/auth_certificates'
export const SIGNATURE_SERVER_SEND_SIGNATURE_LOCATION = '/pem2/addSignature'
export const AUTH_TOKEN_LOCATION = '/auth/token'
export const SERVER_SIGNING_CARD_STATUS_LOCATION = '/card_status'
export const SERVER_CHECK_LOCATION = '/check'
export const SERVER_SHOW_INTERACTION_TIMEOUT = 500
export const SERVER_REQUEST_TIMEOUT = 1.75 * SERVER_SHOW_INTERACTION_TIMEOUT
export const SERVER_REQUEST_INTERVAL = 2 * SERVER_SHOW_INTERACTION_TIMEOUT

export const END_CERTIFICATE = '-----END CERTIFICATE-----'

export const HOM_MANY_CERTIFICATES_TO_INCLUDE = 2

export const ACCESS_TOKEN_OPTIONS = Object.freeze({
  scope: 'openid profile email'
})

export const POST_REQUEST_BODY_TYPE = Object.freeze({
  'Content-Type': 'application/json'
})

export const REQUEST_METHODS = Object.freeze({
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  OPTIONS: 'OPTIONS'
})

export const TYPES_PRESCRIPTION = Object.freeze({
  MEDICINE: 'MEDICINE',
  MANIPULATED: 'MANIPULATED',
  GLUCOSE: 'GLUCOSE',
  OTHERS: 'OTHERS',
  AIR_CHAMBER: 'AIR_CHAMBER'
})

export const AT_MEALS = 'atMeals'
export const MEALS_OPTIONS = 'mealsOptions'
export const PERIOD_OPTIONS = 'periodOptions'
export const PERIODICALLY = 'periodically'
export const WEANING = 'weaning'
export const SOS = 'sos'
export const IN_TIME = 'inTime'
export const ONE_SHOT = 'oneShot'
export const NUMBER_OPTIONS = 'numberOptions'
export const HOURS = 'hours'
export const DAYS_OF_WEEK = 'daysOfWeek'
export const DAYS_OF_MONTH = 'daysOfMonth'
export const SINGLE = 'single'
export const MULTIPLE = 'multiple'
export const PERIODS = ['days', 'weeks', 'months']

export const OPTIONS_LIST = Object.freeze({
  [AT_MEALS]: {
    [MEALS_OPTIONS]: [
      { value: 'fasting' },
      { value: 'breakfast' },
      { value: 'lunch' },
      { value: 'snack' },
      { value: 'dinner' },
      { value: 'supper' }
    ],
    [PERIOD_OPTIONS]: [
      { value: 'nA' },
      { value: 'before' },
      { value: 'during' },
      { value: 'after' }
    ]
  },
  [PERIODICALLY]: {
    [NUMBER_OPTIONS]: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
      { value: 12 }
    ],
    [PERIOD_OPTIONS]: [
      { value: HOURS },
      { value: 'days' },
      { value: 'weeks' },
      { value: 'months' }
    ]
  },
  [WEANING]: {
    [PERIOD_OPTIONS]: [
      { value: 'hours' },
      { value: 'days' },
      { value: 'weeks' },
      { value: 'months' }
    ],
    [NUMBER_OPTIONS]: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
      { value: 12 }
    ]
  },
  [SOS]: {},
  [IN_TIME]: {
    [HOURS]: [
      { value: '0h' },
      { value: '1h' },
      { value: '2h' },
      { value: '3h' },
      { value: '4h' },
      { value: '5h' },
      { value: '6h' },
      { value: '7h' },
      { value: '8h' },
      { value: '9h' },
      { value: '10h' },
      { value: '11h' },
      { value: '12h' },
      { value: '13h' },
      { value: '14h' },
      { value: '15h' },
      { value: '16h' },
      { value: '17h' },
      { value: '18h' },
      { value: '19h' },
      { value: '20h' },
      { value: '21h' },
      { value: '22h' },
      { value: '23h' }
    ],
    [DAYS_OF_WEEK]: [
      { value: 'sunday' },
      { value: 'monday' },
      { value: 'tuesday' },
      { value: 'wednesday' },
      { value: 'thursday' },
      { value: 'friday' },
      { value: 'saturday' }
    ],
    [DAYS_OF_MONTH]: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
      { value: 12 },
      { value: 13 },
      { value: 14 },
      { value: 15 },
      { value: 16 },
      { value: 17 },
      { value: 18 },
      { value: 19 },
      { value: 20 },
      { value: 21 },
      { value: 22 },
      { value: 23 },
      { value: 24 },
      { value: 25 },
      { value: 26 },
      { value: 27 },
      { value: 28 },
      { value: 29 },
      { value: 30 },
      { value: 31 }
    ],
    [PERIOD_OPTIONS]: [
      { value: HOURS },
      { value: DAYS_OF_WEEK },
      { value: DAYS_OF_MONTH }
    ]
  }
})

export const DURATION = Object.freeze({
  SHORT_MEDIUM: '60d',
  LONG: '6M'
})

export const SHARED_WITH_TYPES = Object.freeze({
  EMAIL: 1,
  PHONE: 2
})

export const VALIDATE_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const KENETTO_NAMESPACE = 'https://kenetto.com'

export const KENETTO_USER_METADATA = `${KENETTO_NAMESPACE}/user_metadata`
export const KENETTO_APP_METADATA = `${KENETTO_NAMESPACE}/app_metadata`

export const SIGNING_PROCESS = Object.freeze({
  NOT_STARTED: -1,
  ADDING_SHARING: 0,
  ERROR_ADDING_SHARING: 1,
  AUTHENTICATING_PRESCRIBER: 2,
  ERROR_AUTHENTICATING_PRESCRIBER: 3,
  HASHING_PRESCRIPTION: 4,
  ERROR_HASHING_PRESCRIPTION: 5,
  WAITING_SIGNING: 6,
  ERROR_WAITING_SIGNING: 7,
  ERROR_SIGNING_CANCELED_BY_USER: -3,
  ADDING_DIGITAL_SIGNATURE: 8,
  ERROR_ADDING_DIGITAL_SIGNATURE: 9,
  PRESCRIBING: 10,
  ERROR_PRESCRIBING: 11,
  PRESCRIBED: 12
})

const ONLY_ONE_MSG = Object.freeze([1])
const TWO_MSGS = Object.freeze([1, 2])

/**
 *
 * @type {Readonly<Object<number, {text: string[], progress: number, error: boolean}>>}
 */
export const SIGNING_PROGRESS_TO_TEXT = Object.freeze({
  [SIGNING_PROCESS.NOT_STARTED]: { text: ONLY_ONE_MSG, progress: 0 },
  [SIGNING_PROCESS.ADDING_SHARING]: { text: ONLY_ONE_MSG, progress: 5 },
  [SIGNING_PROCESS.ERROR_ADDING_SHARING]: {
    text: ONLY_ONE_MSG,
    progress: 5,
    error: true
  },
  [SIGNING_PROCESS.AUTHENTICATING_PRESCRIBER]: { text: ONLY_ONE_MSG, progress: 20 },
  [SIGNING_PROCESS.ERROR_AUTHENTICATING_PRESCRIBER]: {
    text: ONLY_ONE_MSG,
    progress: 20,
    error: true
  },
  [SIGNING_PROCESS.HASHING_PRESCRIPTION]: { text: ONLY_ONE_MSG, progress: 35 },
  [SIGNING_PROCESS.ERROR_HASHING_PRESCRIPTION]: {
    text: ONLY_ONE_MSG,
    progress: 35,
    error: true
  },
  [SIGNING_PROCESS.WAITING_SIGNING]: {
    text: TWO_MSGS,
    progress: 50
  },
  [SIGNING_PROCESS.ERROR_WAITING_SIGNING]: {
    text: ONLY_ONE_MSG,
    progress: 50,
    error: true
  },
  [SIGNING_PROCESS.ERROR_SIGNING_CANCELED_BY_USER]: {
    text: ONLY_ONE_MSG,
    progress: 50,
    error: true
  },
  [SIGNING_PROCESS.ADDING_DIGITAL_SIGNATURE]: { text: ONLY_ONE_MSG, progress: 70 },
  [SIGNING_PROCESS.ERROR_ADDING_DIGITAL_SIGNATURE]: {
    text: ONLY_ONE_MSG,
    progress: 70,
    error: true
  },
  [SIGNING_PROCESS.PRESCRIBING]: { text: ONLY_ONE_MSG, progress: 85 },
  [SIGNING_PROCESS.ERROR_PRESCRIBING]: {
    text: ONLY_ONE_MSG,
    progress: 85,
    error: true,
    external: true
  },
  [SIGNING_PROCESS.PRESCRIBED]: { text: ONLY_ONE_MSG, progress: 100 }
})

export const SIGNING_CARD_STATUS = Object.freeze({
  AVAILABLE: 0,
  READER_UNAVAILABLE: 1,
  NO_PICTURE: 2,
  UNKNOWN_CARD: 3,
  CARD_UNAVAILABLE: 4,
  ERROR_READING_DATA: 5,
  CANCELED_DURING_PROCESSING: 6,
  CARD_INTERNAL_ERROR: 7,
  ERROR_READING_ID_DATA: 8,
  READING_CARD: 100,
  SERVER_UNAVAILABLE: 101,
  SERVER_UNREACHABLE: 102,
  CONTACTING_SERVER: 103,
  OLD_VERSION: 104,
  SIGNING: 105
})

export const NEEDED_PERMISSIONS = [
  'sign:cc_card:data',
  'sign:cmd:data'
]

export const KEYS = Object.freeze({
  PROD: 'PROD',
  DEV: 'DEV'
})

let SERVER_ENV_KEY = KEYS.PROD

/**
 * @typedef {Object} Config
 * @property {boolean} IS_PROD
 * @property {string} WEB_API
 * @property {string} REALM_NAME
 * @property {string} CMD_PEM_SERVER_DOMAIN
 * @property {string} REALM_SERVER_DOMAIN
 * @property {string} CMD_PUBLIC_KEY_PATH
 */

/**
 *
 * @type {{CURRENT: Config}}
 */
export const CONFIGS = {
  [KEYS.PROD]: {
    IS_PROD: true,
    WEB_API: 'https://prod-web-api.kenetto.com/',
    REALM_NAME: 'ProdRealm',
    REALM_SERVER_DOMAIN: 'knttesterealm.de1a.cloud.realm.io',
    CMD_PEM_SERVER_DOMAIN: 'https://prod-cmd-pem-new-issuer.kenetto.com',
    CMD_PUBLIC_KEY_PATH: 'ama_prod_public_key.pem',
    IAPHUB_OPTIONS: {
      appId: '5ef22bf69ce7440e7532be3d',
      apiKey: '2MK0M8Gzp12af8svQ63CEtdXZMUIoJe',
      environment: 'production'
    }
  },
  [KEYS.DEV]: {
    IS_PROD: false,
    WEB_API: 'https://pre-prod-web-api-scribo-realm.herokuapp.com',
    REALM_NAME: 'PreProdRealm',
    REALM_SERVER_DOMAIN: 'knttesterealm.de1a.cloud.realm.io',
    CMD_PEM_SERVER_DOMAIN: 'https://pre-prod-knt-cmd.herokuapp.com',
    CMD_PUBLIC_KEY_PATH: 'ama_public_key.pem',
    IAPHUB_OPTIONS: {
      appId: '5ef22bf69ce7440e7532be3d',
      apiKey: '2MK0M8Gzp12af8svQ63CEtdXZMUIoJe',
      environment: 'development'
    }
  },
  /**
   *
   * @return {Config}
   */
  get CURRENT () {
    return this[SERVER_ENV_KEY]
  }
}

/**
 *
 * @param envKey {'PROD'|'DEV'}
 */
export function setEnv (envKey) {
  switch (envKey) {
    case KEYS.PROD:
      SERVER_ENV_KEY = KEYS.PROD
      break
    case KEYS.DEV:
      SERVER_ENV_KEY = KEYS.DEV
      break
    default:
      console.warn(`Attempting to set environment key with value ${envKey} which does not match the possible values ${Object.values(KEYS)}`)
  }
  console.log(`Current env is: ${SERVER_ENV_KEY}`)
}

export const MOMENT_UI_FORMAT = 'L'

export const ActiveMedicationStatus = Object.freeze({
  NOT_STARTED: 1,
  ACTIVE: 2,
  ENDED: 3,
  SUSPENDED: 4,
  DISCONTINUED: 5,
})

export const INFARMED = new InfarmedSdk(new ApiRequestCache())
INFARMED.loadMinimalInfo()

export const SUNDAY = 'sunday'
