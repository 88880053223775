import { v4 } from 'uuid'
import moment from 'moment'

import { TYPES_PRESCRIPTION, ActiveMedicationStatus, INFARMED } from 'src/constants'
import Medicine from 'src/models/Medicine'

import { convertMedicinePosology, convertRealmPosologyToBasket, convertToDuration } from './prescriptionsRealm'

/**
 * @typedef {Object} ActiveMedicationItemStatus
 * @property {string} id
 * @property {number} status
 * @property {string} createdAt
 * @property {string} createdBy
 */

/**
 * @typedef {Object} ActiveMedicationMedicine
 * @property {string} id
 * @property {boolean} requestedGeneric
 * @property {number} dciId
 * @property {number} packageId
 * @property {number} medicineId
 * @property {string} cnpem
 * @property {string} registrationNumber
 * @property {string[]} mainCategories
 * @property {Object<string, RealmPosology>} posologies
 *
 */

/**
 * @typedef {Object} ActiveMedicationItem
 * @property {string} id
 * @property {string} [prescriptionId]
 * @property {string} [prescriptionLine]
 * @property {MEDICINE|MANIPULATED} type
 * @property {string} endsAt
 * @property {ActiveMedicationItemStatus[]} statuses
 * @property {RealmManipulated} [manipulated]
 * @property {ActiveMedicationMedicine} [medicine]
 */

/**
 * @typedef {Object} AddActiveMedication
 * @property {string} type
 * @property {string} [prescriptionId]
 * @property {string} [prescriptionLine]
 */

/**
 * @typedef {AddActiveMedication} AddActiveMedicationMedicine
 * @property {Medicine} medicine
 * @property {MedicinePosology} posology
 * @property {string[]} mainCategories
 * @property {boolean} requestedGeneric
 */

/**
 * @typedef {AddActiveMedication & BasketManipulated} AddActiveMedicationManipulated
 */

/**
 * @typedef {Object} RealmActiveMedication
 * @property {string} id
 * @property {string} prescriptionId
 * @property {string} prescriptionLine
 * @property {string} type
 * @property {string} endsAt
 * @property {Object} [medicine]
 * @property {Object} [manipulated]
 * @property {Object[]} statuses
 */

/**
 *
 * @param alphabeticalOrder {boolean}
 * @return {Promise<Object<string, string>>}
 */
export function getMainCategories (alphabeticalOrder = true) {
  return INFARMED.Category()
    .then(categories => {
      let ordered = categories
      if (alphabeticalOrder) {
        ordered = [...categories].sort((a, b) => a.id - b.id)
      }
      return ordered.reduce((obj, curr) => {
        obj[curr.id] = curr.name
        return obj
      }, {})
    })
}

/**
 *
 * @param medicines  {Medicine[]}
 * @return {Promise<Object<number, {id: number, medId: number}>>}
 */
export function getCategoriesForMedicines (medicines) {
  return medicines.length === 0 ? Promise.resolve({}) : INFARMED.findCategoryForIds(medicines.map(m => m.medicineId))
}

/**
 *
 * @param item {{medicine: {medicineId: number, packageId: number}}}
 * @param medicineDetails {Object<number, {dci: DCI, medicine: Object}>}
 * @return {Medicine}
 */
export function getApplicationMedicine (item, medicineDetails) {
  const { medicine: infarmedMedicine, dci } = medicineDetails[item.medicine.medicineId]
  const infarmedPackage = infarmedMedicine.packagings.find(p => p.id === item.medicine.packageId)
  return new Medicine(dci, infarmedMedicine, infarmedPackage)
}

/**
 *
 * @param item  {ActiveMedicationItem}
 * @return {MedicinePosology}
 */
export function getMedicineActivePosology (item) {
  const posologiesKeys = Object.keys(item.medicine.posologies)
  return convertRealmPosologyToBasket(item.medicine.posologies[posologiesKeys[posologiesKeys.length - 1]])
}

/**
 *
 * @param item {ActiveMedicationItem}
 * @return {RealmPosology[]}
 */
export function getMedicinePosologies (item) {
  return Object.values(item.medicine.posologies)
}

/**
 *
 * @param item {ActiveMedicationItem}
 * @return {MedicinePosology[]}
 */
export function getBasketPosologies (item) {
  return getMedicinePosologies(item).map(convertRealmPosologyToBasket)
}

/**
 *
 * @param item {ActiveMedicationItem}
 * @return {string}
 */
export function getMedicineStartDate (item) {
  const activePosology = getMedicineActivePosology(item)
  return activePosology.scheduling.startDate
}

/**
 *
 * @param item {ActiveMedicationItem}
 * @return {string}
 */
export function getManipulatedStartDate (item) {
  return item.manipulated.startDate
}

const GET_START_DATE_MAP = new Map([
  [TYPES_PRESCRIPTION.MEDICINE, getMedicineStartDate],
  [TYPES_PRESCRIPTION.MANIPULATED, getManipulatedStartDate]
])

/**
 *
 * @param item {ActiveMedicationItem}
 */
export function getItemStartDate (item) {
  const mapper = GET_START_DATE_MAP.get(item.type)
  if (!mapper) return ''
  return mapper(item)
}

/**
 *
 * @param item  {ActiveMedicationItem}
 * @return {string}
 */
export function getActiveMedicationEndDate (item) {
  return item.endsAt
}

/**
 *
 * @param item  {ActiveMedicationItem}
 * @return {number}
 */
export function getActiveState (item) {
  if (!item) return 0
  const statusesKeys = Object.keys(item.statuses)
  const activeStatus = item.statuses[statusesKeys[statusesKeys.length - 1]]
  return activeStatus?.state
}

const LONG_DURATION_TREATMENT = Object.freeze({
  AMOUNT: 200,
  UNIT: 'years'
})

/**
 *
 * @param period {string}
 * @return {'days'|'weeks'|'months'}
 */
function convertPeriodToMomentUnit (period) {
  return period
}

/**
 *
 * @param am {AddActiveMedicationMedicine}
 * @return {MapperToRealmReturnType}
 */
function convertMedicineToActiveMedicationToRealm (am) {
  const { posology: { scheduling } } = am
  const endsAt = moment(scheduling.startDate)
  if (scheduling.longDuration) {
    endsAt.add(LONG_DURATION_TREATMENT.AMOUNT, LONG_DURATION_TREATMENT.UNIT)
  } else {
    endsAt.add(scheduling.numberPeriod, convertPeriodToMomentUnit(scheduling.period))
  }
  return {
    medicine: {
      id: v4(),
      requestedGeneric: am.requestedGeneric,
      dciId: Number(am.medicine.dciId),
      medicineId: Number(am.medicine.medicineId),
      packageId: Number(am.medicine.packagingId),
      cnpem: `${am.medicine.cnpem}`,
      registrationNumber: `${am.medicine.registrationId}`,
      posologies: [convertMedicinePosology(am.posology)],
      mainCategories: am.mainCategories
    },
    status: moment(scheduling.startDate).isSameOrBefore(moment()) ? ActiveMedicationStatus.ACTIVE : ActiveMedicationStatus.NOT_STARTED,
    endsAt: endsAt
  }
}

/**
 *
 * @param activeMedication {AddActiveMedicationManipulated}
 * @return {MapperToRealmReturnType}
 */
function convertManipulatedToActiveMedicationToRealm (activeMedication) {
  const manipulated = activeMedication
  return {
    manipulated: {
      id: v4(),
      activeSubstances: manipulated.activeSubstances,
      packageId: manipulated.packageId || 0,
      pharmaceuticalForm: manipulated.pharmaceuticalForm,
      pharmaceuticalFormId: manipulated.pharmaceuticalFormId || 0,
      administrationType: manipulated.administrationType,
      administrationTypeId: manipulated.administrationTypeId || 0,
      posology: manipulated.posology,
      instructions: manipulated.instructions,
      startDate: manipulated.startDate,
      duration: convertToDuration(manipulated.durationValue, manipulated.durationUnit),
      dosage: Object.values(manipulated.dosage).map(d => ({
        id: v4(),
        text: d.label,
        value: d.value,
        unit: d.unit
      }))
    },
    status: moment(manipulated.startDate).isSameOrBefore(moment()) ? ActiveMedicationStatus.ACTIVE : ActiveMedicationStatus.NOT_STARTED,
    endsAt: moment(manipulated.startDate).add(manipulated.durationValue, convertPeriodToMomentUnit(manipulated.durationUnit))
  }
}

/**
 * @typedef {Object} MapperToRealmReturnType
 * @property {ActiveMedicationStatus} status
 * @property {moment.Moment} endsAt
 * @property {Object} [medicine]
 * @property {Object} [manipulated]
 */

/**
 *
 * @type {Map<string, function(AddActiveMedicationMedicine|AddActiveMedicationManipulated): MapperToRealmReturnType>}
 */
const MAP_CONVERT_TO_REALM = new Map([
  [TYPES_PRESCRIPTION.MEDICINE, convertMedicineToActiveMedicationToRealm],
  [TYPES_PRESCRIPTION.MANIPULATED, convertManipulatedToActiveMedicationToRealm]
])

/**
 *
 * @param activeMedications {(AddActiveMedicationMedicine|AddActiveMedicationManipulated)[]}
 */
export function convertActiveMedicationsToRealm (activeMedications) {
  return activeMedications.map(am => {
    /**
     *
     * @type {RealmActiveMedication}
     */
    const realmActiveMedication = {
      id: v4(),
      prescriptionId: am.prescriptionId || '',
      prescriptionLine: am.prescriptionLine || '',
      type: am.type
    }
    const mapper = MAP_CONVERT_TO_REALM.get(am.type)
    const mapped = mapper(am)
    realmActiveMedication.endsAt = mapped.endsAt.format()
    realmActiveMedication.medicine = mapped.medicine
    realmActiveMedication.manipulated = mapped.manipulated
    realmActiveMedication.statuses = [{ id: v4(), state: mapped.status }]
    return realmActiveMedication
  })
}

export function convertToRealm (newPosology, type) {
  if (type === TYPES_PRESCRIPTION.MANIPULATED) {
    return {
      posology: newPosology.posology,
      instructions: newPosology.instructions,
      startDate: newPosology.startDate,
      duration: convertToDuration(newPosology.durationValue, newPosology.durationUnit)
    }
  } else if (type === TYPES_PRESCRIPTION.MEDICINE) {
    return convertMedicinePosology(newPosology)
  }
  throw new Error(`No type matches ${type}`)
}

export function extractEndDateFromPosology (type, posology) {
  if (type === TYPES_PRESCRIPTION.MANIPULATED) {
    return moment(posology.startDate).startOf('day').add(posology.durationValue, posology.durationUnit).format()
  }
  return moment(posology.scheduling.startDate).startOf('day').add(posology.scheduling.numberPeriod, posology.scheduling.period).format()
}

/**
 *
 * @param posology {MedicinePosology}
 * @return {MedicinePosology}
 */
export function modifyMedicinePosologyForEditing (posology) {
  const newPosology = { ...posology }
  newPosology.schemas = Object.values(posology.schemas).reduce((newSchemas, curr) => {
    /**
     *
     * @type {BaseMedicinePosologySchema}
     */
    const currentSchema = { ...curr }
    const newId = v4()
    newSchemas[newId] = currentSchema
    currentSchema.id = newId
    return newSchemas
  }, {})
  return newPosology
}

/**
 *
 * @param medicine {Medicine}
 * @param categories {Object<number, {medId: number}[]>}
 * @return {string[]}
 */
export function findMedicineCategories (medicine, categories) {
  const set = new Set()
  Object.keys(categories).flatMap(key => categories[key]
    .filter(entry => entry.medId === medicine.medicineId)
    .forEach(_ => set.add(key)))
  return [...set]
}

/**
 *
 * @param item {ActiveMedicationItem}
 * @return {number}
 */
export function getItemDci (item) {
  if (!item || item.type === TYPES_PRESCRIPTION.MANIPULATED) return -1
  return item.medicine.dciId
}

/**
 *
 * @param item {ActiveMedicationItem}
 * @return {boolean}
 */
export function isLongDuration (item) {
  if (!item || item.type === TYPES_PRESCRIPTION.MANIPULATED) return false
  return getMedicineActivePosology(item).scheduling.longDuration
}
