import React, { useContext, useMemo } from 'react'
import { useTranslate } from 'react-polyglot'

import { SIGNING_CARD_STATUS } from 'src/constants'

import './signing_card_status.css'
import cardIcon from './card_icon.svg'
import { eIDSignerContext } from '../../../contexts/eIDSigner'

const STATUS_COLORS = Object.freeze({
  [SIGNING_CARD_STATUS.CONTACTING_SERVER]: Object.freeze({
    borderColor: '#606C70',
    backgroundColor: '#9BA3A5'
  }),
  [SIGNING_CARD_STATUS.READER_UNAVAILABLE]: Object.freeze({
    borderColor: '#606C70',
    backgroundColor: '#9BA3A5'
  }),
  [SIGNING_CARD_STATUS.UNKNOWN_CARD]: Object.freeze({
    borderColor: '#d86161',
    backgroundColor: '#EF6767'
  }),
  [SIGNING_CARD_STATUS.CARD_UNAVAILABLE]: Object.freeze({
    borderColor: '#d86161',
    backgroundColor: '#EF6767'
  }),
  [SIGNING_CARD_STATUS.ERROR_READING_DATA]: Object.freeze({
    borderColor: '#d86161',
    backgroundColor: '#EF6767'
  }),
  [SIGNING_CARD_STATUS.READING_CARD]: Object.freeze({
    borderColor: '#F5900C',
    backgroundColor: '#F9A443'
  }),
  [SIGNING_CARD_STATUS.AVAILABLE]: Object.freeze({
    borderColor: '#089C14',
    backgroundColor: '#37C63C'
  }),
  [SIGNING_CARD_STATUS.NO_PICTURE]: Object.freeze({
    borderColor: '#089C14',
    backgroundColor: '#37C63C'
  }),
  [SIGNING_CARD_STATUS.SERVER_UNREACHABLE]: Object.freeze({
    borderColor: '#000000',
    backgroundColor: '#798184'
  }),
  [SIGNING_CARD_STATUS.SERVER_UNAVAILABLE]: Object.freeze({
    borderColor: '#000000',
    backgroundColor: '#798184'
  }),
  [SIGNING_CARD_STATUS.OLD_VERSION]: Object.freeze({
    borderColor: '#000000',
    backgroundColor: '#798184'
  }),
  [SIGNING_CARD_STATUS.SIGNING]: Object.freeze({
    borderColor: '#ff9400',
    backgroundColor: '#F9A443'
  })
})

const SigningCardStatus = React.memo(function SigningCardStatus () {
  const context = useContext(eIDSignerContext)
  const t = useTranslate()
  const divStyle = useMemo(() => STATUS_COLORS[context.cardStatus] || STATUS_COLORS[SIGNING_CARD_STATUS.SERVER_UNAVAILABLE], [context.cardStatus])
  return <div className='card-status d-flex bd-highlight align-items-center py-2 px-1 ml-1' style={divStyle}>
    <span className='card-icon px-1'><img src={cardIcon} alt={t('cardStatus.iconAlt')} /></span>
    <div className='d-flex align-items-center px-1'>
      <div>{t(`cardStatus.${context.cardStatus}.text`)}</div>
      {context.cardStatus === SIGNING_CARD_STATUS.SERVER_UNAVAILABLE &&
        <button type='button' className='retry-button btn btn-secondary btn-sm d-flex align-items-center ml-1'
                onClick={context.retryServer}>
          <i className='fas fa-redo-alt' />
          <div className='ml-1'>{t('cardStatus.tryAgain')}</div>
        </button>}
    </div>
  </div>
})

export default SigningCardStatus
