const EXCEPTIONS = Object.freeze({
  EXCEPTION_A: 1,
  EXCEPTION_B: 2,
  EXCEPTION_C: 3
})

const LONG_DURATION_ID = 2

export default class Medicine {
  constructor (dci, medicine, packaging) {
    this._dci = dci
    this._medicine = medicine
    this._packaging = packaging
    this._fullId = `${packaging.id}_${packaging.nationalID}`
  }

  get copayment () {
    return this._packaging.copayment
  }

  get packageType () {
    return this._packaging.packageType
  }

  getFullDesignation () {
    return `${this.activeComponent}, ${this.dose}, ${this.type}, ${this.packageType} - ${this.units} unidade(s)`
  }

  get medicineId () {
    return this._medicine.id
  }

  get id () {
    return this._packaging.id
  }

  get packagingId () {
    return this._packaging.id
  }

  get fullId () {
    return this._fullId
  }

  get commercialName () {
    return this._medicine.name
  }

  get activeComponent () {
    return this._dci.name
  }

  get dose () {
    return this.dosage
  }

  get dosage () {
    return this._medicine.dosage
  }

  get units () {
    return this._packaging.packageUnits
  }

  get type () {
    return this._medicine.pharmForm
  }

  get administrationType () {
    return this._medicine.adminWay
  }

  get price () {
    return this._packaging.price
  }

  get pharmFormId () {
    return this._medicine.pharmForm.id
  }

  get cnpem () {
    return this._packaging.nationalID
  }

  get registrationId () {
    return this._packaging.registrationId
  }

  get packagingDescription () {
    return this._packaging.description
  }

  get packagingName () {
    return this._packaging.packageType.name
  }

  get dciId () {
    return this._dci.id
  }

  /**
   *
   * @returns {boolean}
   */
  get isGeneric () {
    return this._medicine.generic
  }

  /**
   *
   * @returns {boolean}
   */
  get hasGenericsAvailable () {
    return this._medicine.genericsAvailable
  }

  /**
   *
   * @returns {boolean}
   */
  get hasNarrowMargin () {
    return this._medicine.narrowMargin
  }

  /**
   *
   * @returns {boolean}
   */
  get isRenewable () {
    return this._packaging.renewable === LONG_DURATION_ID
  }

  /**
   *
   * @returns {boolean}
   */
  get longTreatment () {
    return this.isRenewable
  }

  /**
   * @return {boolean}
   */
  get isNarcotic () {
    return this._medicine.isNarcotic
  }

  /**
   *
   * @returns {number}
   */
  get packageTypeId () {
    return this._packaging.packageType.id
  }

  /**
   *
   * @returns {number}
   */
  get unitaryPackagingId () {
    return this._packaging.unitaryPackaging ? 1 : 2
  }

  /**
   *
   * @returns {boolean}
   */
  get hasUnitaryPackaging () {
    return this._packaging.unitaryPackaging
  }

  /**
   *
   * @return {boolean}
   */
  get canPrescribe () {
    return this._packaging.canPrescribe
  }

  static exceptions () {
    return {}
  }
}
Medicine.EXCEPTIONS = EXCEPTIONS
