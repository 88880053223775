import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  SERVER_CHECK_VALUE,
  SERVER_CHECK_LOCATION,
  SIGNATURE_SERVER_PATH,
  SIGNING_CARD_STATUS,
  SERVER_SIGNING_CARD_STATUS_LOCATION,
  SERVER_SHOW_INTERACTION_TIMEOUT,
  SERVER_REQUEST_TIMEOUT,
  SERVER_REQUEST_INTERVAL, CC_SIGN_LOCATION, END_CERTIFICATE, SERVER_AUTHENTICATION_CERTIFICATES_LOCATION
} from '../constants'

const DEFAULTS = Object.freeze({
  DEFAULT_PORT: 9000
})

function dontLog () {}

const LOGGER = dontLog //console.log

export const eIDSignerContext = React.createContext({})

function canSign (cardStatus) {
  switch (cardStatus) {
    case SIGNING_CARD_STATUS.AVAILABLE:
    case SIGNING_CARD_STATUS.NO_PICTURE:
      return true
    default:
      return false
  }
}

/**
 *
 * @param {string} hash
 * @param {string} baseUrl
 * @returns {Promise<any>}
 */
function postHash (hash, baseUrl) {
  const url = new URL(CC_SIGN_LOCATION, baseUrl)
  return fetch(url.href, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      requestType: 'sign',
      hashes: [hash]
    })
  })
    .then(resp => {
      if (!resp.ok) throw new Error('Not ok')
      return resp.json()
    })
}

/**
 *
 * @param {URL} certificatesUrl
 * @returns {Promise<string>}
 */
function retrieveAndPrepareSignCertificates (certificatesUrl) {
  return fetch(certificatesUrl.href)
    .then(resp => {
      if (!resp.ok) throw new Error('Failed retrieving the certificates')
      return resp.text()
    })
    .then(certificates => certificates.split(END_CERTIFICATE).slice(0, 2).map(entry => `${entry.replaceAll('\n', '')}${END_CERTIFICATE}`).join(''))
}

function retrieveAuthCertificates (baseUrl) {
  const url = new URL(SERVER_AUTHENTICATION_CERTIFICATES_LOCATION, baseUrl)
  return fetch(url.href)
    .then(resp => {
      if (!resp.ok) throw new Error('Failed retrieving the authentication certificates')
      return resp.json()
    })
}

/**
 *
 * @returns {number}
 */
function getRandomID () {
  return Math.round(Math.random() * 100000)
}

export const EIDSignerProvider = React.memo(function ({ children }) {
  const lastRequestPromise = useRef(-1)
  const requestedSigning = useRef(false)
  const currentIntervalId = useRef(-1)
  const [port,] = useState(DEFAULTS.DEFAULT_PORT)
  const [cardStatus, setCardStatus] = useState(SIGNING_CARD_STATUS.CONTACTING_SERVER)
  const [userInfo, setUserInfo] = useState(null)
  const [serverValidated, setServerValidated] = useState(false)

  const baseUrl = useMemo(() => `${SIGNATURE_SERVER_PATH}:${port}`, [port])

  const validateServer = useCallback(() => {
    const url = new URL(SERVER_CHECK_LOCATION, baseUrl)
    setCardStatus(SIGNING_CARD_STATUS.CONTACTING_SERVER)
    fetch(url.href)
      .then(resp => {
        if (!resp.ok) throw new Error(`No server at port ${port}`)
        return resp.json()
      })
      .then(jsonResponse => {
        let status = SIGNING_CARD_STATUS.SERVER_UNREACHABLE
        if (!!jsonResponse && !!jsonResponse.checkValue) {
          if (jsonResponse.checkValue === SERVER_CHECK_VALUE) {
            status = SIGNING_CARD_STATUS.READING_CARD
          } else {
            status = SIGNING_CARD_STATUS.OLD_VERSION
          }
        }
        setServerValidated(true)
        setCardStatus(status)
      })
      .catch(_ => {
        setServerValidated(false)
        setCardStatus(SIGNING_CARD_STATUS.SERVER_UNAVAILABLE)
      })
  }, [baseUrl, port, setServerValidated, setCardStatus])

  const checkCardStatus = useCallback(() => {
    LOGGER(`Checking card status at date: ${Date.now()}, lastRequestPromise: ${lastRequestPromise.current}`)
    const url = new URL(SERVER_SIGNING_CARD_STATUS_LOCATION, baseUrl)
    if (lastRequestPromise.current === -1 && !requestedSigning.current) {
      const randomId = lastRequestPromise.current = getRandomID()
      LOGGER(`No other checks in place, current checking has id: ${randomId}, ${Date.now()}`)
      const requestPromise = fetch(url.href)
        .then(resp => {
          if (lastRequestPromise.current !== randomId || requestedSigning.current) {
            let logText = `Ignoring request ${randomId} for signing request`
            if (lastRequestPromise.current !== randomId) logText = `Ignoring request ${randomId} for request ${lastRequestPromise.current}`
            LOGGER(logText)
            return
          }
          if (!resp.ok) {
            setCardStatus(SIGNING_CARD_STATUS.CONTACTING_SERVER)
            setServerValidated(false)
            setUserInfo(null)
          }
          return resp.json()
        })
        .then(jsonResponse => {
          if (lastRequestPromise.current !== randomId || requestedSigning.current) {
            let logText = `Ignoring request ${randomId} for signing request`
            if (lastRequestPromise.current !== randomId) logText = `Ignoring request ${randomId} for request ${lastRequestPromise.current}`
            LOGGER(logText)
            return
          }
          setCardStatus(jsonResponse.operationCode)
          setUserInfo(jsonResponse.userInfo || null)
        })
        .catch(e => {
          console.error('Rejected?', e)
          clearInterval(currentIntervalId.current)
          currentIntervalId.current = -1
          setCardStatus(SIGNING_CARD_STATUS.CONTACTING_SERVER)
          setUserInfo(null)
          setServerValidated(false)
        })
        .finally(() => {
          if (lastRequestPromise.current === randomId) lastRequestPromise.current = -1
        })
      Promise.race([requestPromise, new Promise((resolve) => setTimeout(resolve.bind(null, randomId), SERVER_SHOW_INTERACTION_TIMEOUT))])
        .then(value => {
          if (value === randomId) {
            setCardStatus(SIGNING_CARD_STATUS.READING_CARD)
          }
        })
      Promise.race([requestPromise, new Promise((_, reject) => setTimeout(reject.bind(null, new Error('Request timedout')), SERVER_REQUEST_TIMEOUT))])
        .catch(() => {
          if (lastRequestPromise.current === randomId) lastRequestPromise.current = -1
        })
    } else if (!requestedSigning.current) {
      console.warn(`checkCardStatus will not be performed because there is a request happening`)
    }
  }, [baseUrl, setCardStatus, setServerValidated, setUserInfo])

  const getAuthenticationCertificates = useCallback(async () => {
    let previousCardStatus
    try {
      lastRequestPromise.current = getRandomID()
      setCardStatus(previous => {
        previousCardStatus = previous
        return SIGNING_CARD_STATUS.SIGNING
      })
      return await retrieveAuthCertificates(baseUrl)
    } catch (e) {
      return null
    } finally {
      setCardStatus(previousCardStatus)
      lastRequestPromise.current = -1
    }
  }, [baseUrl])

  const sign = useCallback(async hash => {
    let previousCardStatus
    try {
      requestedSigning.current = true
      setCardStatus(previous => {
        previousCardStatus = previous
        return SIGNING_CARD_STATUS.SIGNING
      })
      const serverResponse = await postHash(hash, baseUrl)
      const { signed, certificates: certificatesLink, operationCode } = serverResponse
      if (operationCode === 3) {
        return { canceledByUser: true }
      }
      const certificates = await retrieveAndPrepareSignCertificates(new URL(certificatesLink, baseUrl))
      return { signed, certificates, canceledByUser: operationCode === 3 }
    } catch (e) {
      return {}
    } finally {
      setCardStatus(previousCardStatus)
      requestedSigning.current = false
    }
  }, [baseUrl, setCardStatus])

  useEffect(() => {
    if (!serverValidated) {
      validateServer()
    }
  }, [validateServer, serverValidated])

  useEffect(() => {
    let intervalId = -1
    if (serverValidated) {
      LOGGER(`useEffect that sets up setInterval "${currentIntervalId.current}"`)
      if (currentIntervalId.current !== -1) {
        LOGGER(`Cleared interval with id: ${currentIntervalId.current}`)
        clearInterval(currentIntervalId.current)
        currentIntervalId.current = -1
      }
      intervalId = currentIntervalId.current = setInterval(checkCardStatus, SERVER_REQUEST_INTERVAL)
      LOGGER(`Did setup new interval with id: ${currentIntervalId.current}`)
    }
    return () => clearInterval(intervalId)
  }, [checkCardStatus, serverValidated])

  const contextValue = useMemo(() => ({
    port,
    cardStatus,
    userInfo,
    retryServer: validateServer,
    getAuthenticationCertificates: canSign(cardStatus) ? getAuthenticationCertificates : null,
    sign: canSign(cardStatus) ? sign : null
  }), [port, cardStatus, userInfo, validateServer, sign, getAuthenticationCertificates])
  return <eIDSignerContext.Provider value={contextValue}>{children}</eIDSignerContext.Provider>
})
