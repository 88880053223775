import { useCallback, useMemo } from 'react'

import { useTranslate } from 'react-polyglot'


/**
 *
 * @param initialKey {string}
 * @return {function(key: string, options?: number|Object = null): string}
 */
export function useAdaptivePolyglot (initialKey) {
  const t = useTranslate()
  const has = useMemo(() => t._polyglot.has.bind(t._polyglot), [t])
  return useCallback((key, options = null) => {
    const composedKey = `${initialKey}.${key}`
    if (has(composedKey)) {
      return t(composedKey, options)
    }
    return t(key, options)
  }, [t, has, initialKey])
}

/**
 * @typedef {function} Translate
 * @param {string} initialKey
 * @param {number|Object} [options]
 */

/**
 *
 * @param initialKey {string}
 * @return {function(key: string, options?: number|Object = null): string}
 */
export function usePolyglot (initialKey) {
  const t = useTranslate()
  return useCallback((key, options = null) => t(`${initialKey}.${key}`, options), [t, initialKey])
}
