import moment from 'moment'
import {
  ACCESS_TOKEN_OPTIONS, AT_MEALS,
  AUTH_TOKEN_LOCATION,
  CC_SIGN_LOCATION, CONFIGS, DAYS_OF_MONTH, DAYS_OF_WEEK,
  HOM_MANY_CERTIFICATES_TO_INCLUDE, HOURS, IN_TIME, INFARMED, KENETTO_APP_METADATA,
  KENETTO_USER_METADATA, MEALS_OPTIONS, MOMENT_UI_FORMAT, ONE_SHOT, OPTIONS_LIST, PERIOD_OPTIONS, PERIODICALLY,
  REQUEST_METHODS,
  SERVER_AUTHENTICATION_CERTIFICATES_LOCATION, SIGNATURE_SERVER_CONFIRM_PRESCRIPTION_LOCATION,
  SIGNATURE_SERVER_PATH,
  SIGNATURE_SERVER_SEND_SIGNATURE_LOCATION, SOS,
  SPMS_HASH_PRESCRIPTION,
  SUNDAY, TYPES_PRESCRIPTION
} from './constants'
import { registerUsage } from './utils/mostFrequent'
import medicineOptions from './medicineUnitOptions.json'
import {
  convertActiveMedicationsToRealm,
  findMedicineCategories,
  getCategoriesForMedicines
} from './utils/activeMedication'

export async function authorizedFetch (accessToken, requestPath, requestMethod = 'GET', requestBody = null, headerOptions, ignoreResponseData = false) {
  const options = {
    method: requestMethod,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headerOptions
    }
  }
  if (requestBody) {
    options.body = JSON.stringify(requestBody)
    options.headers['Content-Type'] = 'application/json'
  }

  try {
    const response = await fetch(requestPath, options)
    if (ignoreResponseData) return response
    return await response.json()
  } catch (error) {
    throw error
  }
}

export function fetchRealmAPI (auth0, apiRelativePath, ...options) {
  const path = new URL(`/api${apiRelativePath}`, CONFIGS.CURRENT.WEB_API)
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, path, ...options))
    .catch('Error while getting the access token')
}

export function getAuthenticationCertificates (port) {
  const path = new URL(SERVER_AUTHENTICATION_CERTIFICATES_LOCATION, SIGNATURE_SERVER_PATH)
  path.port = port
  return fetch(path.href)
    .then(response => response.json())
    .catch(error => console.log(error))
}

export function extractPictureUserToken (professional, token) {
  return professional?.picture || token.picture
}

/**
 *
 * @param auth0
 * @param certificates {string[]}
 * @param prescriberInfo {{prescriptionLocale: string, orderCode: number, fiscalId: string}}
 * @return {*}
 */
export function authenticatePrescriber (auth0, certificates, prescriberInfo) {
  const url = new URL(AUTH_TOKEN_LOCATION, CONFIGS.CURRENT.CMD_PEM_SERVER_DOMAIN)
  const requestPayload = {
    type: 1,
    certificates: certificates.slice(0, HOM_MANY_CERTIFICATES_TO_INCLUDE),
    NIF: prescriberInfo.fiscalId,
    prescriptionLocaleCode: prescriberInfo.prescriptionLocale,
    orderCode: prescriberInfo.orderCode,
    activate: true
  }
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, url.href, REQUEST_METHODS.POST, requestPayload))
}

export function getPrescriptionHash (auth0, spmsPrescription) {
  const url = new URL(SPMS_HASH_PRESCRIPTION, CONFIGS.CURRENT.CMD_PEM_SERVER_DOMAIN)
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, url.href, REQUEST_METHODS.POST, spmsPrescription))
}

/**
 *
 * @param hash {string}
 * @param port {string}
 * @return {Promise<any | void>}
 */
export function requestHashSigning (hash, port) {
  const path = new URL(CC_SIGN_LOCATION, SIGNATURE_SERVER_PATH)
  path.port = port
  return fetch(path.href, {
    method: REQUEST_METHODS.POST,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      requestType: 'sign',
      hashes: [hash]
    })
  })
    .then(response => response.json())
    .then(respData => {
      if (respData.operationCode !== 0) {
        throw new Error('Error signing')
      }
      const certificatesUrl = new URL(respData.certificates, SIGNATURE_SERVER_PATH)
      certificatesUrl.port = port
      return fetch(certificatesUrl.href)
        .then(async response => {
          const data = await response.text()
          return { ...respData, certificates: data }
        })
    })
    .catch(error => {
      console.log(error)
    })
}

export function addSignature (auth0, id, signature, certificates) {
  const url = new URL(SIGNATURE_SERVER_SEND_SIGNATURE_LOCATION, CONFIGS.CURRENT.CMD_PEM_SERVER_DOMAIN)
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, url.href, REQUEST_METHODS.POST, {
      id,
      signature,
      certificates
    }, {}, true))
}

export function prescribe (auth0, id, token, emails) {
  const url = new URL(SIGNATURE_SERVER_CONFIRM_PRESCRIPTION_LOCATION, CONFIGS.CURRENT.CMD_PEM_SERVER_DOMAIN)
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, url.href, REQUEST_METHODS.POST, {
      id,
      token,
      emails
    }))
}

export function registerMedicationUsage (auth0, prescription, basketItems) {
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(async accessToken => registerUsage(accessToken, basketItems, prescription))
}

export function registerActiveMedication (auth0, activeMedications, nhsId) {
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, new URL('/api/active_medication', CONFIGS.CURRENT.WEB_API), REQUEST_METHODS.POST, {
      activeMedications,
      nhsId
    }, {}, true))
}

export function registerOnClinicalFile (auth0, prescriptionId) {
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => authorizedFetch(accessToken, new URL('/api/prescriptions/registerClinicalFile', CONFIGS.CURRENT.WEB_API), REQUEST_METHODS.POST, { prescriptionId }, {}, true))
}

export function convertToActiveMedications (prescription, basketItems) {
  const prescriptionItems = Object.values(prescription.lines).filter(item => item.type === TYPES_PRESCRIPTION.MEDICINE || item.type === TYPES_PRESCRIPTION.MANIPULATED)
  return getCategoriesForMedicines(prescriptionItems.filter(item => item.type === TYPES_PRESCRIPTION.MEDICINE).map(item => item.medicine))
    .then(categories => {
      const activeMedications = prescriptionItems.map((item, index) => {
        const basketItem = basketItems[index]
        if (item.type === TYPES_PRESCRIPTION.MEDICINE) {
          return {
            type: item.type,
            prescriptionId: prescription.id,
            prescriptionLine: item.id,
            medicine: basketItem.medicine,
            posology: basketItem.posology,
            requestedGeneric: basketItem.requestedGeneric,
            mainCategories: findMedicineCategories(basketItem.medicine, categories)
          }
        } else {
          return {
            prescriptionId: prescription.id,
            prescriptionLine: item.id,
            ...basketItem
          }
        }
      })
      return convertActiveMedicationsToRealm(activeMedications)
    })
}

/**
 *
 * @type {string[]}
 */
const UNITS = ['year', 'month', 'day', 'hour']

/**
 *
 * @param birthDate {string}
 * @return {{unit: string, difference: number}}
 */
export function calculateAge (birthDate) {
  const momentBirthDate = moment(birthDate)
  const now = moment()
  let difference
  let unitIndex = 0
  for (; unitIndex < UNITS.length; unitIndex++) {
    difference = now.diff(momentBirthDate, UNITS[unitIndex])
    if (difference > 0) {
      break
    }
  }
  return { difference, unit: UNITS[unitIndex] }
}

/**
 *
 * @param medicine {Medicine}
 * @return {{unitaryPackagingId: number, pharmFormId: number, dciId: (string|number|BigInt), packageTypeId: (number|string)}}
 */
export function extractUnitsIds (medicine) {
  return {
    dciId: medicine.dciId,
    pharmFormId: medicine.pharmFormId,
    packageTypeId: medicine.packageTypeId,
    unitaryPackagingId: medicine.unitaryPackagingId
  }
}

/**
 *
 * @param dciId {string|number}
 * @param pharmFormId {string|number}
 * @param packageTypeId {string|number}
 * @param unitaryPackagingId {string|number}
 * @returns {{singular: Array<string>, plural: Array<string>, useFractions: boolean}}
 * @private
 */
function _convertToPossibleUnits (dciId, pharmFormId, packageTypeId, unitaryPackagingId) {
  return medicineOptions[dciId][pharmFormId][packageTypeId][unitaryPackagingId]
}

/**
 *
 * @param dciIdOrObj {string|number|{dciId: string|number, pharmFormId: string|number, packageTypeId:string|number, unitaryPackagingId: string|number}}
 * @param pharmFormId {string|number}
 * @param packageTypeId {string|number}
 * @param unitaryPackagingId {string|number}
 * @returns {{singular: Array<string>, plural: Array<string>, useFractions: boolean}}
 */
export function convertToPossibleUnits (dciIdOrObj, pharmFormId = '', packageTypeId = '', unitaryPackagingId = '') {
  if (typeof dciIdOrObj === 'object') {
    return _convertToPossibleUnits(dciIdOrObj.dciId, dciIdOrObj.pharmFormId, dciIdOrObj.packageTypeId, dciIdOrObj.unitaryPackagingId)
  }
  return _convertToPossibleUnits(dciIdOrObj, pharmFormId, packageTypeId, unitaryPackagingId)
}

/**
 *
 * @param text {Array<string>}
 * @param andText {string}
 * @returns {string}
 */
function joinWithAnd (text, andText) {
  const joined = text.join(', ')
  const lastComma = joined.lastIndexOf(',')
  return `${joined.substring(0, lastComma)} ${andText}${joined.substring(lastComma + 1)}`
}

/**
 *
 * @param dosage {{numerator: string, denominator: string, useFraction: boolean}}
 * @return {{quantity: string, isPlural: (boolean|boolean)}}
 */
function extractQuantity (dosage) {
  let quantity = dosage.useFraction ? `${dosage.numerator}/${dosage.denominator}` : `${dosage.numerator}`
  quantity = quantity.replace('.', ',')
  const isPlural = !dosage.useFraction && dosage.numerator > 1
  return {
    quantity: quantity,
    isPlural: isPlural
  }
}

/**
 * @typedef {Object} Dosage
 * @property {number} numerator
 * @property {number} denominator
 * @property {number} unitsIndex
 * @property {number} unitsId
 * @property {string} unitsText
 * @property {boolean} useFraction
 * @property {boolean} valid
 */

/**
 *
 * @param posology {{numerator: string, denominator: string, useFraction: boolean}}
 * @param units {{singular: Array<{id:number, name: string}>, plural: Array<{id:number, name: string}>}}
 * @param dosageUnitsInfo {Dosage}
 */
function composeQuantityAndUnits (posology, units, dosageUnitsInfo) {
  const { quantity, isPlural } = extractQuantity(posology)
  const unitOptions = isPlural ? units.plural : units.singular
  let unitsName
  if (dosageUnitsInfo.unitsId > 0) {
    let foundUnit = unitOptions.find(u => u.id === dosageUnitsInfo.unitsId)
    if (!foundUnit) {
      foundUnit = { name: 'Unidade Desconhecida' }
      console.error(`Could not find unit for id: ${dosageUnitsInfo.unitsId}`)
    }
    unitsName = foundUnit.name
  } else if (dosageUnitsInfo.unitsId === -1) {
    unitsName = dosageUnitsInfo.unitsText
  }
  return `${quantity} ${unitsName}`
}

/**
 *
 * @param schema {BaseSchema}
 * @param t {Translation}
 * @return {string}
 */
function adjustDuration (schema, t) {
  return t('customDurationDescription', {
    startDate: moment(schema.startDate).format(MOMENT_UI_FORMAT),
    duration: t(`smart${schema.period}`, Number(schema.numberPeriod)),
    smart_count: schema.lastsToEnd ? 2 : 1
  })
}

/**
 * @typedef {function} Translation
 * @param {string} key
 * @param {Object|number} [options]
 * @returns {string}
 */

/**
 * @typedef {Object} BaseSchema
 * @property {string} units
 * @property {boolean} adjustedDosage
 * @property {string} numerator
 * @property {string} denominator
 * @property {boolean} useFraction
 * @property {boolean} adjustedDuration
 * @property {string} startDate
 * @property {string} numberPeriod
 * @property {string} period
 * @property {string} lastsToEnd
 */

/**
 * @typedef {BaseSchema} PeriodicallySchema
 * @property {string} numberOption
 * @property {string} periodOption
 */

/**
 * @param quantity {string}
 * @param item {PeriodicallySchema}
 * @param t {Translation}
 * @returns {string}
 */
function descriptionPeriodically (quantity, item, t) {
  const number = Number(item.numberOption)
  const unit = t(`timeUnits.${item.periodOption}`, { smart_count: number })
  return t('periodicallyDescription', { smart_count: number, quantity: quantity, value: number, unit: unit })
}

/**
 * @typedef {BaseSchema} AtMealsSchema
 * @property {Array<boolean>} mealsOptions
 * @property {string} periodOptions
 */

const FASTING_INDEX = 0
const NA_VALUE = OPTIONS_LIST[AT_MEALS][PERIOD_OPTIONS][0].value

/**
 *
 * @param quantity {string}
 * @param item {AtMealsSchema}
 * @param t {Translation}
 * @returns {string}
 */
function descriptionAtMeals (quantity, item, t) {
  const period = item.periodOptions
  const mappedValues = item.mealsOptions.map((_, index) => OPTIONS_LIST[AT_MEALS][MEALS_OPTIONS][index].value)
  const mealsOptions = item.mealsOptions.map((val, index) => val && mappedValues[index]).filter(val => !!val)
  if (mealsOptions.length === 1) {
    return t(`atMealsDescription.singleMealPeriod.${period}.${mealsOptions[0]}`, { quantity: quantity })
  } else {
    const periodsString = joinWithAnd(mealsOptions.map(key => t(`atMealsDescription.${key}`)), t('atMealsDescription.and'))
    if (period === NA_VALUE) {
      return t(`atMealsDescription.multipleMealsPeriods.${period}.${item.mealsOptions[FASTING_INDEX] ? mappedValues[FASTING_INDEX] : 'others'}`, {
        quantity: quantity,
        periods: periodsString
      })
    } else {
      return t(`atMealsDescription.multipleMealsPeriods.${period}`, {
        quantity: quantity,
        periods: periodsString
      })
    }
  }
}

/**
 * @typedef {BaseSchema} InTimeSchema
 * @property {string} periodOption
 * @property {Array<boolean>} hours
 * @property {Array<boolean>} daysOfWeek
 * @property {Array<boolean>} daysOfMonth
 */

const SUNDAY_INDEX = 0

/**
 *
 * @param quantity {string}
 * @param item {InTimeSchema}
 * @param t {Translation}
 * @returns {string}
 */
function descriptionInTime (quantity, item, t) {
  const period = item.periodOption
  const selectedValues = item[period]
  const mappedValues = selectedValues.map((_, index) => OPTIONS_LIST[IN_TIME][period][index].value)
  const activeValues = item[period].map((val, index) => val && mappedValues[index]).filter(val => !!val)
  let translatedValues
  switch (period) {
    case DAYS_OF_WEEK:
      translatedValues = activeValues
        .map(val => t(`inTimeDescription.${val}`))
      break
    case DAYS_OF_MONTH:
    case HOURS:
    default:
      translatedValues = activeValues
      break
  }
  if (activeValues.length > 1) {
    translatedValues = joinWithAnd(translatedValues, t('inTimeDescription.and'))
    if (period === DAYS_OF_WEEK) {
      return t(`inTimeDescription.multiple.${DAYS_OF_WEEK}.${selectedValues[SUNDAY_INDEX] ? SUNDAY : 'others'}`, {
        quantity: quantity,
        multiple: translatedValues
      })
    } else {
      return t(`inTimeDescription.multiple.${period}`, { quantity: quantity, multiple: translatedValues })
    }
  } else {
    return t(`inTimeDescription.single.${period}`, { quantity: quantity, single: translatedValues[0] })
  }
}

/**
 * @typedef {BaseSchema} SosSchema
 * @property {string} if
 * @property {string} until
 */

/**
 *
 * @param quantity {string}
 * @param item {SosSchema}
 * @param t {Translation}
 * @returns {string}
 */
function descriptionSos (quantity, item, t) {
  let userText = ''
  if (item.if && item.until) {
    userText = `${t('sosDescription.if', { if: item.if })} ${t('sosDescription.until', { until: item.until })}`
  } else if (item.if) {
    userText = `${t('sosDescription.if', { if: item.if })}`
  } else if (item.until) {
    userText = `${t('sosDescription.until', { until: item.until })}`
  }
  if (userText) return `${t(`sosDescription.description`, { quantity: quantity })}, ${userText}`
  return `${t(`sosDescription.description`, { quantity: quantity })}`
}

/**
 *
 * @param quantity {string}
 * @param item {SosSchema}
 * @param t {Translation}
 * @returns {string}
 */
function descriptionOneShot (quantity, item, t) {
  return t('oneShotDescription.description', { quantity: quantity })
}

/**
 * @type {Map<string, function(quantity: string,item: Object, t: Translation): string>}
 */
const descriptors = new Map([
  [PERIODICALLY, descriptionPeriodically],
  [AT_MEALS, descriptionAtMeals],
  [IN_TIME, descriptionInTime],
  [SOS, descriptionSos],
  [ONE_SHOT, descriptionOneShot]
])

/**
 *
 * @param units {{singular: Array<{id: number, name: string}>, plural: Array<{id: number, name: string}>}}
 * @param posology {Object}
 * @param item {Object}
 * @param t
 * @return {string}
 */
export function textDescriptionForItem (units, posology, item, t) {
  const descriptionGenerator = descriptors.get(item.type)
  if (descriptionGenerator) {
    const quantity = composeQuantityAndUnits(item.adjustedDosage ? item : posology.dosage, units, posology.dosage)
    let description = descriptionGenerator(quantity, item, t)
    if (item.adjustedDuration) {
      description += ` ${adjustDuration(item, t)}`
    }
    return description
  }
  throw new Error(`textDescriptionForItem: Could not find a description generator for type: (${item.type})`)
}

/**
 *
 * @param posology {Object}
 * @param t {function(string, object?): string}
 * @param [includeFor] {boolean}
 * @return {string}
 */
export function getDurationForPosology (posology, t, includeFor = true) {
  const duration = posology.scheduling.longDuration ? t('posology.longDuration') : `${t(`smart${posology.scheduling.period}`, Number(posology.scheduling.numberPeriod))}`
  return includeFor ? `${t('posology.duration')} ${duration}` : duration
}

export function generatePosologies (basketItems, tPosology) {
  return Promise.all(basketItems.map(async item => {
    switch (item.type) {
      case TYPES_PRESCRIPTION.MEDICINE:
        const { medicine, posology } = item
        const units = await INFARMED.units(extractUnitsIds(medicine))
        return `${Object.values(posology.schemas)
          .map(schema => textDescriptionForItem(units, posology, schema, tPosology))
          .join(', ')} | ${getDurationForPosology(posology, tPosology, true)}`
      default:
        return item.posology || item.instructions
    }
  }))
}

/**
 *
 * @param user {Object}
 * @returns {boolean}
 */
export function isDev (user) {
  const metadata = user[KENETTO_APP_METADATA]
  return metadata?.dev?.isDev
}
