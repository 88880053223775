import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import './prescription_info.css'
import moment from 'moment'
import { calculateAge } from '../../../common_functions'
import { useTranslate } from 'react-polyglot'

const PrescriptionInfo = React.memo(function PrescriptionInfo ({ photoUrl, partialName, date }) {
  const t = useTranslate()
  const { difference, unit } = useMemo(() => calculateAge(date), [date])
  const key = useMemo(() => `ages.${unit}`, [unit])
  return <div className="patient-info d-flex flex-sm-column flex-xl-row bd-highlight align-items-center justify-content-around py-2">
    <div className="patient-photo-frame">
      <img id="patient-photo" src={photoUrl} alt="Profile" />
    </div>
    <div className="d-flex flex-column flex-xl-row justify-content-center justify-content-lg-between align-content-center">
      <span className="mx-1 font-weight-bold text-center largeSize">{partialName}</span>
      <span className="mx-1 text-center py-1">{t(key, difference)} ({moment(date).format('L')})</span>
    </div>
  </div>
})

PrescriptionInfo.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  partialName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
}

export default PrescriptionInfo
