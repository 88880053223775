import React, { useMemo } from 'react'

/**
 *
 * @param string {string}
 * @return {string|*}
 */
function capitalize (string) {
  if (!string || string.length === 0) {
    return ''
  } else if (string.length === 1) {
    return string.toUpperCase()
  }
  return `${string.substring(0, 1).toUpperCase()}${string.substring(1).toLowerCase()}`
}

/**
 *
 * @param name {string}
 * @return {string}
 */
function modifyName (name) {
  if (name.startsWith('Linha')) {
    return `${name.substring(name.indexOf(' ') + 1)}ª Linha de prescrição`
  }
  return name
}

const SpmsProcessError = React.memo(function ProcessError ({ errorKey, value }) {
  const processedKey = useMemo(() => modifyName(capitalize(errorKey)), [errorKey])
  return <div className='d-flex flex-column justify-content-between my-1'>
    <span className='progressText'>{processedKey}</span>
    <span className='secondaryProgressText'>{value}</span>
  </div>
})

export default SpmsProcessError
