/**
 * @typedef {Object} RealmEFREntityInfo
 * @property {string} id
 * @property {string} code
 * @property {string} [name]
 * @property {string} domain
 * @property {string} countryCode
 */

/**
 * @typedef {Object} RealmRECM
 * @property {string} id
 * @property {string} type
 * @property {string} code
 */

/**
 * @typedef {Object} RealmEFR
 * @property {string} id
 * @property {string} beneficiaryNumber
 * @property {RealmEFREntityInfo} entityInfo
 */

/**
 * @typedef {Object} RealmHealthSystemDetails
 * @property {string} id
 * @property {string} [prescriptionId]
 * @property {string} [localPrescriptionId]
 * @property {string} [dispensationCode]
 * @property {string} [optionCode]
 * @property {string} [registrationDate]
 * @property {string[]} [diplomas]
 * @property {RealmEFR} efr
 * @property {RealmRECM} [recm]
 * @property {string} pdf
 */

/**
 * @typedef {Object} RealmPerLineHealthSystemDetails
 * @property {string} id
 * @property {string} [singleActCode]
 * @property {string} [singleActText]
 * @property {string} [singleActDuration]
 */

/**
 * @typedef {Object} RealmNonGenericJustification
 * @property {string} id
 * @property {number} code
 * @property {string} dataAssociated
 */

/**
 * @typedef {Object} RealmPosologySchema
 * @property {string} id
 * @property {string} type
 * @property {boolean} adjustedDosage
 * @property {boolean} useFraction
 * @property {number} numerator
 * @property {number} denominator
 * @property {boolean} adjustedDuration
 * @property {string} startDate
 * @property {number} numberPeriod
 * @property {string} period
 * @property {string} duration
 * @property {AtMealsSchema} atMeals
 * @property {PeriodicallySchema} periodically
 * @property {InTimeSchema} inTime
 * @property {SOSSchema} sos
 */

/**
 * @typedef {Object} RealmPosology
 * @property {string} id
 * @property {string} startDate
 * @property {string} [duration]
 * @property {boolean} longTreatment
 * @property {string} comment
 * @property {number} numerator
 * @property {number} denominator
 * @property {number} unitsIndex
 * @property {RealmPosologySchema[]|Object<string, RealmPosologySchema>} schemas
 */

/**
 * @typedef {Object} RealmMedicine
 * @property {string} id
 * @property {boolean} requestedGeneric
 * @property {number} dciId
 * @property {number} medicineId
 * @property {number} packageId
 * @property {number} priceId
 * @property {string} cnpem
 * @property {string} registrationId
 * @property {RealmNonGenericJustification} [justification]
 * @property {RealmPosology} posology
 */

/**
 * @typedef {Object} RealmPrescriptionDosage
 * @property {string} id
 * @property {string} [text]
 * @property {string} value
 * @property {number} unit
 */

/**
 * @typedef {Object} RealmManipulated
 * @property {string} id
 * @property {string} activeSubstances
 * @property {string} packageId
 * @property {RealmPrescriptionDosage[]} dosage
 * @property {string} pharmaceuticalForm
 * @property {number} pharmaceuticalFormId
 * @property {string} administrationType
 * @property {number} administrationTypeId
 * @property {string} posology
 * @property {string} instructions
 * @property {string} duration
 * @property {string} startDate
 */

/**
 * @typedef {Object} RealmOthers
 * @property {string} id
 * @property {RealmPrescriptionDosage[]} components
 * @property {string} pharmaceuticalForm
 * @property {number} pharmaceuticalFormId
 * @property {string} administrationType
 * @property {number} administrationTypeId
 * @property {string} posology
 * @property {string} instructions
 * @property {string} duration
 * @property {string} startDate
 */

/**
 * @typedef {Object} RealmAirChamber
 * @property {string} id
 * @property {number} chamberId
 * @property {number} chamberTypeId
 * @property {string} registrationNumber
 * @property {string} instructions
 * @property {string} duration
 * @property {string} startDate
 */

/**
 * @typedef {Object} RealmGlucose
 * @property {string} id
 * @property {number} glucoseId
 * @property {string} registrationNumber
 * @property {string} instructions
 * @property {string} duration
 * @property {string} startDate
 */

/**
 * @typedef {Object} RealmPrescriptionLine
 * @property {string} id
 * @property {string} type
 * @property {string} validFor
 * @property {string} expiresAt
 * @property {number} quantity
 * @property {RealmPerLineHealthSystemDetails} healthSystemDetails
 * @property {RealmMedicine} medicine
 * @property {RealmManipulated} manipulated
 * @property {RealmOthers} others
 * @property {RealmAirChamber} airchamber
 * @property {RealmGlucose} glucose
 */

/**
 * @typedef {Object} RealmPrescriptionSharing
 * @property {string} id
 * @property {number} type
 * @property {string} value
 */

/**
 * @typedef {Object} RealmPrescription
 * @property {string} id
 * @property {string} healthId
 * @property {boolean} expired
 * @property {string} [prescribed]
 * @property {RealmHealthSystemDetails} healthSystemDetails
 * @property {RealmPrescriptionLine[]|Object<string, RealmPrescriptionLine>} lines
 * @property {RealmPrescriptionSharing[]} sharedWith
 */

/**
 * @typedef {Object} RealmReduxPrescription
 * @property {string} id
 * @property {string} healthId
 * @property {boolean} expired
 * @property {string} [prescribed]
 * @property {RealmHealthSystemDetails} healthSystemDetails
 * @property {Object<string,RealmPrescriptionLine>} lines
 * @property {Object<string, RealmPrescriptionSharing>} sharedWith
 */

/**
 * @typedef {Object} SPMSPatient
 * @property {string} nhsId
 * @property {string} fullName
 * @property {string} birthDate RFC3339 date
 * @property {'M'|'F'} gender F/M
 * @property {string} placeOfBirth RFC3339 date
 * @property {string} nationality ISO3166-2
 * @property {string[]} emails
 */

/**
 * @typedef {Object} SPMSEFREntity
 * @property {string} code
 * @property {string} [designation]
 * @property {string} [domain]
 * @property {string} [country]
 */

/**
 * @typedef {Object} SPMSEFR
 * @property {SPMSEFREntity} entity
 * @property {string} beneficiaryNumber
 * @property {string} [emittedDate]
 * @property {string} [expiresAt]
 * @property {string} [beginningDate]
 */

/**
 * @typedef {Object} SPMSRECM
 * @property {string} type Must be either R or O
 * @property {string} code
 */

/**
 * @typedef {Object} SPMSPrescriber
 * @property {number} orderNumber
 * @property {number} orderCode
 * @property {string} fullName
 * @property {string} clinicalName
 * @property {number} [specialty]
 * @property {string} contact
 */

/**
 * @typedef {Object} SPMSMedicationInfo
 * @property {number} [cnpem] Must be filled for a generic medicine prescription
 * @property {string} [registryNumber] If its a prescription by commercial name this field must have a value
 * @property {number} [dciId] Must be filled for a generic medicine prescription
 * @property {number} [pharmaceuticalFormId] Must be filled for a generic medicine prescription
 * @property {string} [packageDescription] Must be filled for a generic medicine prescription
 * @property {string} [dosageDescription] Must be filled for a generic medicine prescription
 * @property {boolean} [isNarcotic] If the medicine is a narcotic
 * @property {string} [description] A description for manipulated/others prescriptions
 * @property {'ORAL'|'SMALL'|'MEDIUM'|'LARGE'} [maskType] Must be filled if an air chamber is being prescribed
 */

/**
 * @typedef {Object} SPMSPosologyInfo
 * @property {boolean} [isProlonged]
 * @property {string} description The description of the posology that includes a duration text
 * @property {string} duration The duration should be in the format {number}[dwMy]
 */

/**
 * @typedef {Object} SPMSExceptionInfo
 * @property {number} exceptionCode
 * @property {number} [treatmentDuration]
 */

/**
 * @typedef {Object} SPMSExtensionInfo
 * @property {'POSOLOGY'|'STABILITY_CHRONIC'|'PROLONGED_ABSENCE'|'OTHER'} justification
 * @property {string} [description] The text when the justification is OTHER
 * @property {string} [howLong] This value is in months
 */

/**
 * @typedef {Object} SPMSMedication
 * @property {'MEDICINE'|'DIABETES'|'MANIPULATED'|'EXPANDING'|'OTHERS'} typeMedication The type
 * @property {SPMSMedicationInfo} medicationInfo
 * @property {boolean} isGenericPrescription
 * @property {boolean} areGenericsAvailable
 * @property {SPMSPosologyInfo} posologyInfo
 * @property {SPMSExceptionInfo} [exceptionInfo]
 * @property {string[]} [diplomaCodes]
 * @property {number} quantity
 * @property {SPMSExtensionInfo} [extensionInfo]
 */

/**
 * @typedef {Object} SPMSPrescription
 * @property {string} scriboId The id of the prescription inside scribo
 * @property {SPMSPatient} patient The nhs patient information for this prescription
 * @property {SPMSEFR} efr The financially responsible entity (basically who will pay the copay)
 * @property {SPMSRECM} [recm] The special copay regime applied
 * @property {SPMSPrescriber} prescriber
 * @property {SPMSMedication[]} medications
 * @property {string} prescriptionLocale
 * @property {string} prescriptionLocaleName
 */

/**
 * @typedef {Object} RECM
 * @property {string} type
 * @property {string} code
 */

/**
 * @typedef {Object} EFREntity
 * @property {string} id
 * @property {string} code
 * @property {string|null} name
 * @property {string} domain
 * @property {string} countryCode
 */

/**
 * @typedef {Object} EFR
 * @property {string} beneficiaryNumber
 * @property {EFREntity} entityInfo
 */

/**
 * @typedef {Object} PatientDetails
 * @property {string} healthId
 * @property {Array<string>} diplomas
 * @property {RECM|null} recm
 * @property {EFR|null} efr
 */

/**
 * @typedef {Object} BaseMedicinePosologySchema
 * @property {string} type
 * @property {boolean} adjustedDosage
 * @property {boolean} useFraction
 * @property {string} numerator
 * @property {string} denominator
 * @property {boolean} adjustedDuration
 * @property {string} startDate
 * @property {string} numberPeriod
 * @property {string} period
 * @property {string} id
 * @property {boolean} [editable]
 */

/**
 * @typedef {BaseMedicinePosologySchema} PeriodicallySchema
 * @property {string} numberOption
 * @property {string} periodOption
 */

/**
 * @typedef {BaseMedicinePosologySchema} SOSSchema
 * @property {string} if
 * @property {string} until
 */

/**
 * @typedef {BaseMedicinePosologySchema} AtMealsSchema
 * @property {Array<boolean>} mealsOptions
 * @property {string} periodOptions
 */

/**
 * @typedef {BaseMedicinePosologySchema} InTimeSchema
 * @property {Array<boolean>} hours
 * @property {Array<boolean>} daysOfWeek
 * @property {Array<boolean>} daysOfMonth
 * @property {string} periodOption
 */

/**
 * @typedef {Object} MedicinePosology
 * @property {Object} dosage
 * @property {number} dosage.unitsIndex
 * @property {string} dosage.numerator
 * @property {string} dosage.denominator
 * @property {boolean} dosage.useFraction
 * @property {Object} scheduling
 * @property {string} scheduling.startDate
 * @property {string} scheduling.numberPeriod
 * @property {string} scheduling.period
 * @property {boolean} scheduling.longDuration
 * @property {Object} instructions
 * @property {string} instructions.patientInstructions
 * @property {Object<string, BaseMedicinePosologySchema>} schemas
 */

/**
 * @typedef {Object} MedicineDetails
 * @property {number} dciId
 * @property {number} medicineId
 * @property {number} packagingId
 * @property {string} cnpem
 * @property {string} registrationId
 */

/**
 * @typedef {Object} BasketMedicine
 * @property {boolean} requestedGeneric
 * @property {string} duration
 * @property {number} quantity
 * @property {boolean} singleActActive
 * @property {string} singleActCode
 * @property {string} singleActText
 * @property {string} singleActDuration
 * @property {string} type
 * @property {MedicineDetails} medicine
 * @property {MedicinePosology} posology
 * @property {number} justification
 * @property {string|undefined|null} justificationData
 */

/**
 * @typedef {Object} BasketAirChamber
 * @property {string} id
 * @property {string} airChamberText
 * @property {number} quantity
 * @property {string} instructions
 * @property {string} startDate
 * @property {string} durationUnit
 * @property {string} durationValue
 * @property {string} type
 * @property {Object} airChamber
 * @property {number} airChamber.id
 * @property {number} airChamber.chamberTypeId
 * @property {string} airChamber.registryNumber
 */

/**
 * @typedef {Object} BasketGlucose
 * @property {string} id
 * @property {string} type
 * @property {string} glucoseText
 * @property {Object} glucose
 * @property {string} glucoseId
 * @property {string} instructions
 * @property {string} quantity
 * @property {string} startDate
 * @property {string} durationUnit
 * @property {string} durationValue
 */

/**
 * @typedef {Object} BasketManipulated
 * @property {string} id
 * @property {string} type
 * @property {string} activeSubstances
 * @property {string} packageId
 * @property {Object<string, {id: string, value: string, unit: number, label: string}>} dosage
 * @property {string} pharmaceuticalForm
 * @property {string} pharmaceuticalFormId
 * @property {string} administrationType
 * @property {string} administrationTypeId
 * @property {string} posology
 * @property {string} instructions
 * @property {string} quantity
 * @property {string} startDate
 * @property {string} durationUnit
 * @property {string} durationValue
 */

/**
 * @typedef {Object} BasketOthers
 * @property {string} id
 * @property {string} type
 * @property {Object<string, {id: string, value: string, unit: number, text: string}>} components
 * @property {string} pharmaceuticalForm
 * @property {string} pharmaceuticalFormId
 * @property {string} administrationType
 * @property {string} administrationTypeId
 * @property {string} posology
 * @property {string} instructions
 * @property {string} quantity
 * @property {string} startDate
 * @property {string} durationUnit
 * @property {string} durationValue
 */

/**
 * @typedef {Object} Basket
 * @property {Object<string, BasketMedicine|BasketAirChamber|BasketGlucose|BasketManipulated|BasketOthers>} values
 */
import { v4 } from 'uuid'
import moment from 'moment'

import { AT_MEALS, DURATION, INFARMED, IN_TIME, PERIODICALLY, PERIODS, SOS, TYPES_PRESCRIPTION } from 'src/constants'
import Medicine from '../models/Medicine'

import { INFARMED_CONSTANTS } from './indexes_and_jsons'

const MAX_SPMS_POSOLOGY_SIZE = 500
const TRUNCATED_POSOLOGY_SUFFIX = '...'
const TRUNCATED_POSOLOGY_SUFFIX_LENGTH = TRUNCATED_POSOLOGY_SUFFIX.length

const DURATION_VALUES = Object.freeze({
  DAYS: 'd',
  WEEKS: 'w',
  MONTHS: 'M',
  years: 'Y'
})

export function convertToDuration (value, unit) {
  switch (unit) {
    case PERIODS[0]:
      return `${value}${DURATION_VALUES.DAYS}`
    case PERIODS[1]:
      return `${value}${DURATION_VALUES.WEEKS}`
    case PERIODS[2]:
      return `${value}${DURATION_VALUES.MONTHS}`
    default:
      return ''
  }
}

/**
 *
 * @param text {string}
 * @return {string}
 */
function extractNumberFromDuration (text) {
  return text.substring(0, text.length - 1)
}

/**
 *
 * @param text {string}
 * @return {string}
 */
function convertFromDuration (text) {
  switch (text.substring(text.length - 1)) {
    case DURATION_VALUES.DAYS:
      return PERIODS[0]
    case DURATION_VALUES.WEEKS:
      return PERIODS[1]
    case DURATION_VALUES.MONTHS:
      return PERIODS[2]
    default:
      return PERIODS[0]
  }
}

/**
 *
 * @param duration {string}
 * @return {string}
 */
function convertDurationUnit (duration) {
  const isPlural = Number(extractNumberFromDuration(duration)) !== 1
  switch (duration.substring(duration.length - 1)) {
    case DURATION_VALUES.DAYS:
      return isPlural ? 'dias' : 'dia'
    case DURATION_VALUES.WEEKS:
      return isPlural ? 'semanas' : 'semana'
    case DURATION_VALUES.MONTHS:
      return isPlural ? 'meses' : 'mês'
    default:
      return isPlural ? 'dias' : 'dia'
  }
}

/**
 *
 * @param prescription {RealmPrescription}
 * @returns {RealmPrescriptionLine[]}
 */
function convertLinesToList (prescription) {
  return Array.isArray(prescription.lines) ? prescription.lines : Object.values(prescription.lines)
}

const MAP_TO_DAYS = Object.freeze({
  [DURATION_VALUES.DAYS]: 1,
  [DURATION_VALUES.WEEKS]: 7,
  [DURATION_VALUES.MONTHS]: 30
})

/**
 *
 * @param duration {string}
 * @return {number}
 */
function convertDurationToDays (duration) {
  const value = extractNumberFromDuration(duration)
  const multiplier = MAP_TO_DAYS[duration.substring(duration.length - 1)] || 1
  return value * multiplier
}

/**
 *
 * @param schema {AtMealsSchema}
 * @returns {Object}
 */
function atMealsMapper (schema) {
  return {
    mealsOptions: schema.mealsOptions,
    periodOptions: schema.periodOptions
  }
}

/**
 *
 * @param schema {InTimeSchema}
 * @returns {Object}
 */
function inTimeMapper (schema) {
  return {
    hours: schema.hours,
    daysOfWeek: schema.daysOfWeek,
    daysOfMonth: schema.daysOfMonth,
    periodOption: schema.periodOption
  }
}

/**
 *
 * @param schema {PeriodicallySchema}
 * @returns {Object}
 */
function periodicallyMapper (schema) {
  return {
    numberOption: `${schema.numberOption}`,
    periodOption: schema.periodOption
  }
}

/**
 *
 * @param schema {SOSSchema}
 * @returns {Object}
 */
function sosMapper (schema) {
  return {
    if: schema.if,
    until: schema.until
  }
}

/**
 *
 * @type {Map<string, function(BaseMedicinePosologySchema): Object>}
 */
const SpecificSchemaMapper = new Map([
  [AT_MEALS, atMealsMapper],
  [IN_TIME, inTimeMapper],
  [PERIODICALLY, periodicallyMapper],
  [SOS, sosMapper]
])

/**
 * @param schema {BaseMedicinePosologySchema}
 */
export function mapPosologySchema (schema) {
  const mappedSchema = {
    id: schema.id,
    type: schema.type,
    adjustedDosage: schema.adjustedDosage,
    numerator: Number(schema.numerator || 0),
    denominator: Number(schema.denominator || 0),
    useFraction: schema.useFraction,
    adjustedDuration: schema.adjustedDuration,
    startDate: schema.startDate,
    duration: convertToDuration(schema.numberPeriod, schema.period)
  }
  const mapper = SpecificSchemaMapper.get(schema.type)
  if (mapper) {
    mappedSchema[schema.type] = mapper(schema)
  }
  return mappedSchema
}

/**
 *
 * @param posology {MedicinePosology}
 * @return {RealmPosology}
 */
export function convertMedicinePosology (posology) {
  return {
    id: v4(),
    startDate: posology.scheduling.startDate,
    duration: convertToDuration(posology.scheduling.numberPeriod, posology.scheduling.period),
    longTreatment: posology.scheduling.longDuration,
    comment: posology.instructions.patientInstructions,
    numerator: Number(posology.dosage.numerator),
    denominator: Number(posology.dosage.denominator),
    unitsIndex: posology.dosage.unitsIndex,
    schemas: Object.values(posology?.schemas || {}).map(mapPosologySchema)
  }
}

/**
 *
 * @param medicine {BasketMedicine}
 */
function mapMedicineLine (medicine) {
  const realmData = {
    id: v4(),
    type: medicine.type,
    validFor: medicine.duration,
    quantity: medicine.quantity,
    medicine: {
      id: v4(),
      requestedGeneric: medicine.requestedGeneric,
      dciId: Number(medicine.medicine.dciId),
      medicineId: Number(medicine.medicine.medicineId),
      packageId: Number(medicine.medicine.packagingId),
      cnpem: String(medicine.medicine.cnpem),
      registrationNumber: String(medicine.medicine.registrationId),
      posology: convertMedicinePosology(medicine.posology)
    },
    healthSystemDetails: {
      id: v4(),
      singleActCode: medicine.singleActCode,
      singleActText: medicine.singleActText,
      singleActDuration: medicine.singleActActive ? convertToDuration(medicine.singleActDuration, PERIODS[2]) : undefined
    }
  }
  if (medicine.justification) {
    realmData.medicine.justification = {
      id: v4(),
      code: medicine.justification,
      dataAssociated: medicine.posology.scheduling.longDuration ? '' : medicine.posology.scheduling.numberPeriod
    }
  }
  return realmData
}

/**
 *
 * @param airChamber {BasketAirChamber}
 */
function mapAirChamberLine (airChamber) {
  return {
    id: v4(),
    type: airChamber.type,
    validFor: DURATION.SHORT_MEDIUM,
    healthSystemDetails: {
      id: v4()
    },
    quantity: Number(airChamber.quantity || 1),
    airchamber: {
      id: v4(),
      chamberId: airChamber.airChamber.id,
      chamberTypeId: airChamber.airChamber.chamberTypeId,
      registrationNumber: airChamber.airChamber.registryNumber,
      instructions: airChamber.instructions,
      duration: convertToDuration(airChamber.durationValue, airChamber.durationUnit),
      startDate: airChamber.startDate
    }
  }
}

/**
 *
 * @param glucose {BasketGlucose}
 * @returns {{}}
 */
function mapGlucoseLine (glucose) {
  return {
    id: v4(),
    type: glucose.type,
    validFor: DURATION.SHORT_MEDIUM,
    healthSystemDetails: {
      id: v4()
    },
    quantity: Number(glucose.quantity || 1),
    glucose: {
      id: v4(),
      glucoseId: glucose.glucose.id,
      registrationNumber: glucose.glucose.registryNumber,
      instructions: glucose.instructions,
      duration: convertToDuration(glucose.durationValue, glucose.durationUnit),
      startDate: glucose.startDate
    }
  }
}

/**
 *
 * @param manipulated {BasketManipulated}
 * @returns {Object}
 */
function mapManipulatedLine (manipulated) {
  return {
    id: v4(),
    type: manipulated.type,
    validFor: DURATION.SHORT_MEDIUM,
    healthSystemDetails: {
      id: v4()
    },
    quantity: Number(manipulated.quantity || 1),
    manipulated: {
      id: v4(),
      activeSubstances: manipulated.activeSubstances,
      packageId: manipulated.packageId || 0,
      pharmaceuticalForm: manipulated.pharmaceuticalForm,
      pharmaceuticalFormId: manipulated.pharmaceuticalFormId || 0,
      administrationType: manipulated.administrationType,
      administrationTypeId: manipulated.administrationTypeId || 0,
      posology: manipulated.posology,
      instructions: manipulated.instructions,
      startDate: manipulated.startDate,
      duration: convertToDuration(manipulated.durationValue, manipulated.durationUnit),
      dosage: Object.values(manipulated.dosage).map(d => ({
        id: v4(),
        text: d.label,
        value: d.value,
        unit: d.unit
      }))
    }
  }
}

/**
 *
 * @param others {BasketOthers}
 * @returns {Object}
 */
function mapOthers (others) {
  return {
    id: v4(),
    type: others.type,
    validFor: DURATION.SHORT_MEDIUM,
    healthSystemDetails: {
      id: v4()
    },
    quantity: Number(others.quantity || 1),
    others: {
      id: v4(),
      pharmaceuticalForm: others.pharmaceuticalForm,
      pharmaceuticalFormId: others.pharmaceuticalFormId,
      administrationType: others.administrationType,
      administrationTypeId: others.administrationTypeId,
      posology: others.posology,
      instructions: others.instructions,
      startDate: others.startDate,
      duration: convertToDuration(others.durationValue, others.durationUnit),
      components: Object.values(others.components).map(d => ({
        id: v4(),
        text: d.text,
        value: d.value,
        unit: d.unit
      }))
    }
  }
}

/**
 *
 * @param basket {Basket}
 * @return {RealmPrescriptionLine[]}
 */
function mapBasketValues (basket) {
  return Object.values(basket.values).map(value => {
    let mapper
    switch (value.type) {
      case TYPES_PRESCRIPTION.MEDICINE:
        mapper = mapMedicineLine
        break
      case TYPES_PRESCRIPTION.AIR_CHAMBER:
        mapper = mapAirChamberLine
        break
      case TYPES_PRESCRIPTION.GLUCOSE:
        mapper = mapGlucoseLine
        break
      case TYPES_PRESCRIPTION.MANIPULATED:
        mapper = mapManipulatedLine
        break
      case TYPES_PRESCRIPTION.OTHERS:
        mapper = mapOthers
        break
      default:
        return null
    }
    return mapper(value)
  })
}

/**
 *
 * @param basket {Basket}
 * @param patientDetails {PatientDetails}
 * @returns {Object}
 */

export function convertFromBasketToRealm (basket, patientDetails) {
  const prescription = {
    id: v4(),
    healthId: patientDetails.healthId,
    status: 1,
    healthSystemDetails: {
      id: v4(),
      efr: patientDetails.efr,
      diplomas: patientDetails.diplomas
    }
  }
  if (patientDetails.recm) {
    prescription.healthSystemDetails.recm = { ...patientDetails.recm, id: v4() }
  }
  prescription.lines = mapBasketValues(basket)
  return prescription
}

/**
 *
 * @param basket {Basket}
 * @return {RealmPrescriptionLine[]}
 */
export function convertBasketToRealmLines (basket) {
  return mapBasketValues(basket)
}

const BASE_SCHEMA_KEYS = ['adjustedDosage', 'useFraction', 'numerator', 'denominator', 'adjustedDuration', 'startDate', 'numberPeriod', 'period', 'id', 'type']

/**
 *
 * @param schema {RealmPosologySchema}
 * @return {BaseMedicinePosologySchema}
 */
function baseRealmSchemaToBasket (schema) {
  const converted = Object.keys(schema)
    .filter(key => BASE_SCHEMA_KEYS.includes(key))
    .reduce((curr, key) => {
      if (key === 'numerator') {
        curr[key] = `${schema[key]}`
      } else {
        curr[key] = schema[key]
      }
      return curr
    }, {})
  if (schema.adjustedDuration) {
    converted.period = convertFromDuration(schema.duration)
    converted.numberPeriod = extractNumberFromDuration(schema.duration)
  } else {
    converted.period = PERIODS[0]
    converted.numberPeriod = ''
  }
  return converted
}

/**
 *
 * @param realmSchema {RealmPosologySchema}
 * @return {BaseMedicinePosologySchema}
 */
function convertAtMealsSchemaToBasket (realmSchema) {
  const schema = baseRealmSchemaToBasket(realmSchema)
  const atMealsDetails = realmSchema.atMeals
  schema.mealsOptions = [...atMealsDetails.mealsOptions]
  schema.periodOptions = atMealsDetails.periodOptions
  return schema
}

/**
 *
 * @param realmSchema {RealmPosologySchema}
 * @return {BaseMedicinePosologySchema & InTimeSchema}
 */
function convertInTimeSchemaToBasket (realmSchema) {
  const schema = baseRealmSchemaToBasket(realmSchema)
  const inTimeDetails = realmSchema.inTime
  schema.hours = [...inTimeDetails.hours]
  schema.daysOfWeek = [...inTimeDetails.daysOfWeek]
  schema.daysOfMonth = [...inTimeDetails.daysOfMonth]
  schema.periodOption = inTimeDetails.periodOption
  return schema
}

/**
 *
 * @param realmSchema {RealmPosologySchema}
 * @return {BaseMedicinePosologySchema}
 */
function convertPeriodicallySchemaToBasket (realmSchema) {
  const schema = baseRealmSchemaToBasket(realmSchema)
  const periodicallyDetails = realmSchema.periodically
  schema.numberOption = Number(periodicallyDetails.numberOption)
  schema.periodOption = periodicallyDetails.periodOption
  return schema
}

/**
 *
 * @param realmSchema {RealmPosologySchema}
 * @return {BaseMedicinePosologySchema}
 */
function convertSosSchemaToBasket (realmSchema) {
  const schema = baseRealmSchemaToBasket(realmSchema)
  const sosDetails = realmSchema.sos
  schema.until = sosDetails.until
  schema.if = sosDetails.if
  return schema
}

/**
 *
 * @type {Map<string, function(RealmPosologySchema): BaseMedicinePosologySchema>}
 */
const REALM_SCHEMAS_TO_BASKET_MAPPERS = new Map([
  [AT_MEALS, convertAtMealsSchemaToBasket],
  [IN_TIME, convertInTimeSchemaToBasket],
  [PERIODICALLY, convertPeriodicallySchemaToBasket],
  [SOS, convertSosSchemaToBasket]
])

/**
 * @param schemas {(RealmPosologySchema)[]}
 * @return {Object<string, BaseMedicinePosologySchema>}
 */
function convertRealmPosologySchemasToBasket (schemas) {
  return schemas.reduce((curr, schema) => {
    const mapper = REALM_SCHEMAS_TO_BASKET_MAPPERS.get(schema.type)
    if (mapper) {
      curr[schema.id] = mapper(schema)
      curr[schema.id].editable = true
    }
    return curr
  }, {})
}

/**
 *
 * @param dosages {RealmPrescriptionDosage[]}
 * @param textKey {string}
 * @return {Object<string, {id:string, value: string, unit: number, text: string|undefined, label: string|undefined}>}
 */
function convertRealmDosages (dosages, textKey = 'text') {
  return dosages.reduce((curr, dosage) => {
    curr[dosage.id] = { id: dosage.id, value: dosage.value, unit: dosage.unit, [textKey]: dosage.text }
    return curr
  }, {})
}

/**
 *
 * @param posology {RealmPosology}
 * @return {MedicinePosology}
 */
export function convertRealmPosologyToBasket (posology) {
  return {
    dosage: {
      unitsIndex: posology.unitsIndex,
      unitsId: posology.unitsId,
      unitsText: posology.unitsText,
      numerator: `${posology.numerator}`,
      denominator: `${posology.denominator}`,
      useFraction: posology.denominator > 1
    },
    scheduling: {
      startDate: posology.startDate,
      longDuration: posology.longTreatment,
      numberPeriod: posology.longTreatment ? 1 : extractNumberFromDuration(posology.duration),
      period: posology.longTreatment ? PERIODS[0] : convertFromDuration(posology.duration)
    },
    instructions: {
      patientInstructions: posology.comment
    },
    schemas: convertRealmPosologySchemasToBasket((Array.isArray(posology.schemas) ? posology.schemas : Object.values(posology.schemas)))
  }
}

/**
 *
 * @param line {RealmPrescriptionLine}
 * @return {BasketMedicine}
 */
async function convertMedicineLineToBasket (line) {
  const realmMedicine = line.medicine
  const basketMedicine = {
    requestedGeneric: realmMedicine.requestedGeneric,
    duration: line.validFor,
    quantity: line.quantity,
    singleActActive: !!line.healthSystemDetails.singleActCode,
    singleActCode: line.healthSystemDetails.singleActCode,
    singleActText: line.healthSystemDetails.singleActText,
    singleActDuration: line.healthSystemDetails.singleActCode ? extractNumberFromDuration(line.healthSystemDetails.singleActDuration) : undefined,
    type: line.type,
    posology: convertRealmPosologyToBasket(realmMedicine.posology),
    justification: realmMedicine.justification?.code,
    justificationData: realmMedicine.justification?.dataAssociated
  }
  const medicineDetails = await INFARMED.getMedicines([{
    medicine: realmMedicine.medicineId,
    package: realmMedicine.packageId,
    price: realmMedicine.priceId
  }])
  const { dci, medicine } = medicineDetails[realmMedicine.medicineId]
  const packaging = medicine.packagings.find(p => p.id === realmMedicine.packageId)
  basketMedicine.medicine = new Medicine(dci, medicine, packaging)
  basketMedicine.id = basketMedicine.medicine.fullId
  return basketMedicine
}

/**
 *
 * @param line {RealmPrescriptionLine}
 * @return {BasketManipulated}
 */
export function convertManipulatedToBasket (line) {
  const realmManipulated = line.manipulated
  const listDosages = Array.isArray(realmManipulated.dosage) ? realmManipulated.dosage : Object.values(realmManipulated.dosage)
  return {
    id: line.id,
    type: line.type,
    activeSubstances: realmManipulated.activeSubstances,
    packageId: realmManipulated.packageId,
    dosage: convertRealmDosages(listDosages, 'label'),
    pharmaceuticalForm: realmManipulated.pharmaceuticalForm,
    pharmaceuticalFormId: realmManipulated.pharmaceuticalFormId,
    administrationType: realmManipulated.administrationType,
    administrationTypeId: realmManipulated.administrationTypeId,
    posology: realmManipulated.posology,
    instructions: realmManipulated.instructions,
    quantity: line.quantity,
    startDate: realmManipulated.startDate,
    durationUnit: convertFromDuration(realmManipulated.duration),
    durationValue: extractNumberFromDuration(realmManipulated.duration)
  }
}

/**
 *
 * @param line {RealmPrescriptionLine}
 * @return {BasketManipulated}
 */
async function convertManipulatedLineToBasket (line) {
  return Promise.resolve(convertManipulatedToBasket(line))
}

/**
 *
 * @param line {RealmPrescriptionLine}
 * @return {BasketAirChamber}
 */
async function convertAirChamberLineToBasket (line) {
  const realmAirChamber = line.airchamber
  const airChamber = INFARMED_CONSTANTS.AIR_CHAMBER.KEYED[realmAirChamber.chamberId]
  return {
    id: line.id,
    airChamberText: airChamber.field,
    quantity: line.quantity,
    instructions: realmAirChamber.instructions,
    startDate: realmAirChamber.startDate,
    durationUnit: convertFromDuration(realmAirChamber.duration),
    durationValue: extractNumberFromDuration(realmAirChamber.duration),
    type: line.type,
    airChamber: airChamber
  }
}

/**
 *
 * @param line {RealmPrescriptionLine}
 * @return {BasketGlucose}
 */
async function convertGlucoseLineToBasket (line) {
  const realmGlucose = line.glucose
  const infarmedGlucose = INFARMED_CONSTANTS.GLUCOSE.KEYED[realmGlucose.glucoseId]
  return {
    id: line.id,
    type: line.type,
    glucoseText: infarmedGlucose.field,
    glucose: infarmedGlucose,
    glucoseId: realmGlucose.glucoseId,
    instructions: realmGlucose.instructions,
    quantity: line.quantity,
    startDate: realmGlucose.startDate,
    durationUnit: convertFromDuration(realmGlucose.duration),
    durationValue: extractNumberFromDuration(realmGlucose.duration)
  }
}

/**
 *
 * @param line {RealmPrescriptionLine}
 * @return {BasketOthers}
 */
async function convertOthersLineToBasket (line) {
  const realmOthers = line.others
  const listDosages = Array.isArray(realmOthers.components) ? realmOthers.components : Object.values(realmOthers.components)
  return {
    id: line.id,
    type: line.type,
    components: convertRealmDosages(listDosages),
    pharmaceuticalForm: realmOthers.pharmaceuticalForm,
    pharmaceuticalFormId: realmOthers.pharmaceuticalFormId,
    administrationType: realmOthers.administrationType,
    administrationTypeId: realmOthers.administrationTypeId,
    posology: realmOthers.posology,
    instructions: realmOthers.instructions,
    quantity: line.quantity,
    startDate: realmOthers.startDate,
    durationUnit: convertFromDuration(realmOthers.duration),
    durationValue: extractNumberFromDuration(realmOthers.duration)
  }
}

/**
 *
 * @type {Map<string, function(RealmPrescriptionLine): Promise<BasketMedicine|BasketManipulated|BasketGlucose|BasketOthers|BasketAirChamber>>}
 */
const MAP_REALM_LINES_TO_BASKET_MAPPERS = new Map([
  [TYPES_PRESCRIPTION.MEDICINE, convertMedicineLineToBasket],
  [TYPES_PRESCRIPTION.MANIPULATED, convertManipulatedLineToBasket],
  [TYPES_PRESCRIPTION.AIR_CHAMBER, convertAirChamberLineToBasket],
  [TYPES_PRESCRIPTION.GLUCOSE, convertGlucoseLineToBasket],
  [TYPES_PRESCRIPTION.OTHERS, convertOthersLineToBasket]
])

/**
 *
 * @param item {{lines: Object<string,RealmPrescriptionLine>|RealmPrescriptionLine[]}}
 * @return {Promise<(BasketAirChamber|BasketMedicine|BasketGlucose|BasketManipulated|BasketOthers)[]>}
 */
export async function convertFromItemWithLinesToBasket (item) {
  /**
   * @type {RealmPrescriptionLine[]}
   */
  const mappedLines = Array.isArray(item.lines) ? item.lines : Object.values(item.lines)
  return Promise.all(mappedLines
    .filter(l => TYPES_PRESCRIPTION[l.type])
    .map(l => MAP_REALM_LINES_TO_BASKET_MAPPERS.get(l.type)(l))
  )
}

/**
 *
 * @param favorite {{lines: Object<string,RealmPrescriptionLine>|RealmPrescriptionLine[]}}
 * @return {Promise<(BasketAirChamber|BasketMedicine|BasketGlucose|BasketManipulated|BasketOthers)[]>}
 */
export async function convertFromFavoriteToBasket (favorite) {
  return convertFromItemWithLinesToBasket(favorite)
}

/**
 *
 * @param lines {Array<{id: string,medicine: number, package: number, price: number, requestedGeneric: boolean, quantity: number}>}
 * @returns {Promise<Object<number, {quantity: number, name: string, dosage: string}>>}
 */
async function extractFromMedicineLines (lines) {
  const medicinesData = await INFARMED.getMedicines(lines)
  return lines.reduce((obj, line) => {
    const id = line.id
    obj[id] = {
      quantity: line.quantity,
      name: line.requestedGeneric ? medicinesData[line.medicine].dci.name : medicinesData[line.medicine].medicine.name,
      dosage: medicinesData[line.medicine]?.medicine.dosage
    }
    return obj
  }, {})
}

/**
 *
 * @param lines {Array<{id: string, type: string, quantity: number, medicine: {requestedGeneric: boolean, medicineId: number, packageId: number, priceId: number}}>}
 * @returns {Promise<Object<number, {quantity: number, name: string, dosage: string}>>}
 */
export async function findMedicineNamesForLines (lines) {
  const medicineLines = lines
    .filter(line => line.type === TYPES_PRESCRIPTION.MEDICINE)
    .map(l => ({
      id: l.id,
      medicine: l.medicine.medicineId,
      package: l.medicine.packageId,
      price: l.medicine.priceId,
      requestedGeneric: l.medicine.requestedGeneric,
      quantity: l.quantity
    }))
  return extractFromMedicineLines(medicineLines)
}

/**
 *
 * @param realmPrescription {{lines: Object<string, {type: string, quantity: number, medicine: {requestedGeneric: boolean, medicineId: number, packageId: number, priceId: number}}>}}
 * @returns {Promise<Object<number, {quantity: number, name: string, dosage?: string}>>}
 */
export async function findMedicineNamesForPrescription (realmPrescription) {
  const medicineLines = Object.values(realmPrescription.lines)
    .filter(line => line.type === TYPES_PRESCRIPTION.MEDICINE)
    .map(l => ({
      id: l.id,
      medicine: l.medicine.medicineId,
      package: l.medicine.packageId,
      price: l.medicine.priceId,
      requestedGeneric: l.medicine.requestedGeneric,
      quantity: l.quantity
    }))
  return extractFromMedicineLines(medicineLines)
}

/**
 *
 * @param airChamberLine {{airchamber: {chamberId: number}}}
 * @returns {{name: string}}
 */
function extractNameForAirChamber (airChamberLine) {
  return {
    name: INFARMED_CONSTANTS.AIR_CHAMBER.KEYED[airChamberLine.airchamber.chamberId]?.field
  }
}

/**
 *
 * @param glucoseLine {{glucose: {glucoseId: number}}}
 * @returns {{name: string}}
 */
function extractNameForGlucose (glucoseLine) {
  return {
    name: INFARMED_CONSTANTS.GLUCOSE.KEYED[glucoseLine.glucose.glucoseId]?.field
  }
}

/**
 *
 * @param othersLine {{others: {components: Object<string,{text: string, value: string,unit: number}>}}}
 * @returns {{name: string}}
 */
function extractNameForOthers (othersLine) {
  return {
    name: Object.values(othersLine.others.components).map(c => {
      if (!c.value) {
        return c.text
      }
      return `${c.text} ${c.value} ${INFARMED_CONSTANTS.UNITS.KEYED[c.unit]?.field}`
    }).join(' + ')
  }
}

/**
 *
 * @param manipulatedLine {{manipulated: {activeSubstances: string, dosage: Object<string, {text?: string, value: string, unit: number}>}}}
 * @returns {{name: string}}
 */
function extractNameForManipulated (manipulatedLine) {
  const arrayDosage = Object.values(manipulatedLine.manipulated.dosage)
  if (arrayDosage.length === 0) {
    return { name: manipulatedLine.manipulated.activeSubstances }
  }
  return {
    name: arrayDosage.length < 2
      ? `${manipulatedLine.manipulated.activeSubstances} ${arrayDosage[0].value} ${INFARMED_CONSTANTS.UNITS.KEYED[arrayDosage[0].unit]?.field}`
      : arrayDosage.map(d => `${d.text} ${d.value} ${INFARMED_CONSTANTS.UNITS.KEYED[d.unit]?.field}`).join(' + ')
  }
}

/**
 *
 * @param lines {Array<{id: string, type: string, airchamber: Object, others: Object, manipulated: Object, glucose: Object, quantity: number}>}
 * @returns {Object<string, {quantity: number, name: string}>}
 */
export function extractFromNonMedicineLines (lines) {
  return lines.reduce((obj, line) => {
    let extractor
    switch (line.type) {
      case TYPES_PRESCRIPTION.AIR_CHAMBER:
        extractor = extractNameForAirChamber
        break
      case TYPES_PRESCRIPTION.GLUCOSE:
        extractor = extractNameForGlucose
        break
      case TYPES_PRESCRIPTION.OTHERS:
        extractor = extractNameForOthers
        break
      case TYPES_PRESCRIPTION.MANIPULATED:
        extractor = extractNameForManipulated
        break
      default:
        break
    }
    if (extractor) {
      const data = extractor(line)
      data.quantity = line.quantity
      obj[line.id] = data
    }
    return obj
  }, {})
}

/**
 *
 * @param realmPrescription {{lines: Object<string, {type: string, quantity: number, medicine: {requestedGeneric: boolean, medicineId: number, packageId: number, priceId: number}}>}}
 */
export function getNameForNonMedicines (realmPrescription) {
  return extractFromNonMedicineLines(Object.values(realmPrescription.lines)
    .filter(line => line.type !== TYPES_PRESCRIPTION.MEDICINE))
}

/**
 *
 * @param realmPrescription {Object}
 * @return {Promise<{}>}
 */
export async function getNamesForPrescription (realmPrescription) {
  return { id: realmPrescription.id, ...await findMedicineNamesForPrescription(realmPrescription), ...getNameForNonMedicines(realmPrescription) }
}

/**
 *
 * @param realmPrescription
 * @returns {{}}
 */
export function convertFromBasketToPendingPrescription (realmPrescription) {
  return {}
}

/**
 * @typedef {Object} ConverterPatientDetails
 * @property {string} healthId
 * @property {string} birthDate
 * @property {string} fullName
 * @property {string} placeOfBirth
 * @property {string} nationality
 * @property {'M'|'F'} gender
 * @property {string} email
 */

/**
 * @typedef {Object} ConverterPrescriberDetails
 * @property {string} prescriptionLocale
 * @property {string} prescriptionLocaleName
 * @property {string} fullName
 * @property {string} clinicalName
 * @property {string} contact
 * @property {number} orderCode
 * @property {number} orderNumber
 * @property {number} specialty
 */

/**
 *
 * @param spmsMedication {SPMSMedication}
 * @param duration {string}
 * @param posologyDescription {string}
 * @param isLongTreatment {boolean}
 * @param isGeneric {boolean=false}
 */
function nonMedicinePosologyAndGenericsInfo (spmsMedication, duration, posologyDescription, isLongTreatment, isGeneric = false) {
  const posologyDescriptionSize = posologyDescription?.length ?? 0
  let spmsAppropriatePosology = posologyDescription
  if (posologyDescriptionSize >= MAX_SPMS_POSOLOGY_SIZE) {
    spmsAppropriatePosology = `${posologyDescription.substring(0, MAX_SPMS_POSOLOGY_SIZE - TRUNCATED_POSOLOGY_SUFFIX_LENGTH - 2)}${TRUNCATED_POSOLOGY_SUFFIX}`
  }
  spmsMedication.posologyInfo = {
    duration: isLongTreatment ? undefined : duration,
    isProlonged: isLongTreatment,
    description: spmsAppropriatePosology
  }
  spmsMedication.isGenericPrescription = isGeneric
  spmsMedication.areGenericsAvailable = isGeneric
}

/**
 * @type {function(spmsMedication: SPMSMedication, realmLine: RealmPrescriptionLine, medicines: Object):void}
 * @param spmsMedication {SPMSMedication}
 * @param realmLine {RealmPrescriptionLine}
 * @param medicines {Object}
 */
async function spmsConvertManipulated (spmsMedication, realmLine, medicines, details, posologyDescription) {
  const { activeSubstances, dosage, duration, posology } = realmLine.manipulated
  spmsMedication.medicationInfo = {
    description: [
      activeSubstances,
      Object.values(dosage).map(d => `${d.value} ${INFARMED_CONSTANTS.UNITS.KEYED[d.unit]?.field}`).join(' + '),
      'Fazer segundo a arte'
    ].filter(a => a).join(', ')
  }
  nonMedicinePosologyAndGenericsInfo(spmsMedication, duration, `${posology} durante ${extractNumberFromDuration(duration)} ${convertDurationUnit(duration)}`, false)
}

/**
 * @type {function(spmsMedication: SPMSMedication, realmLine: RealmPrescriptionLine, medicines: Object):void}
 * @param spmsMedication {SPMSMedication}
 * @param realmLine {RealmPrescriptionLine}
 * @param medicines {Object}
 */
async function spmsConvertOthers (spmsMedication, realmLine, medicines) {
  const { components, posology, duration } = realmLine.others
  const description = Object.values(components || {}).reduce((acc, curr) => {
    acc.names.push(curr.text)
    if (curr.value) {
      acc.dosages.push(`${curr.value} ${INFARMED_CONSTANTS.UNITS.KEYED[curr.unit]?.field}`)
    } else {
      acc.dosages.push('N.D.')
    }
    return acc
  }, { names: [], dosages: [] })
  spmsMedication.medicationInfo = {
    description: `${description.names.join(' e ')}, ${description.dosages.join(' + ')}`
  }
  nonMedicinePosologyAndGenericsInfo(spmsMedication, duration, `${posology} durante ${extractNumberFromDuration(duration)} ${convertDurationUnit(duration)}`, false)
}

/**
 * @type {function(spmsMedication: SPMSMedication, realmLine: RealmPrescriptionLine, medicines: Object):void}
 * @param spmsMedication {SPMSMedication}
 * @param realmLine {RealmPrescriptionLine}
 * @param medicines {Object}
 */
async function spmsConvertGlucose (spmsMedication, realmLine, medicines) {
  const { glucoseId, registrationNumber, duration, instructions } = realmLine.glucose
  const glucose = INFARMED_CONSTANTS.GLUCOSE.KEYED[glucoseId]
  spmsMedication.medicationInfo = {
    registryNumber: registrationNumber,
    description: `${glucose.field}, ${glucose.dosage || 'N.D.'}, ${glucose.pharmaceuticalForm}, ${glucose.packaging}`
  }

  nonMedicinePosologyAndGenericsInfo(spmsMedication, duration, `${instructions} durante ${extractNumberFromDuration(duration)} ${convertDurationUnit(duration)}`, false)
}

const CONVERT_FROM_INFARMED_TO_SERVER = Object.freeze({
  9: 1,
  10: 2,
  11: 3,
  12: 4
})

/**
 * @type {function(spmsMedication: SPMSMedication, realmLine: RealmPrescriptionLine, medicines: Object):void}
 * @param spmsMedication {SPMSMedication}
 * @param realmLine {RealmPrescriptionLine}
 * @param medicines {Object}
 */
async function spmsConvertAirChamber (spmsMedication, realmLine, medicines) {
  const { chamberId, chamberTypeId, duration, instructions } = realmLine.airchamber
  const airChamber = INFARMED_CONSTANTS.AIR_CHAMBER.KEYED[chamberId]
  spmsMedication.medicationInfo = {
    registryNumber: airChamber.registryNumber,
    description: `${airChamber.field}, ${airChamber.dosage}, ${airChamber.pharmaceuticalForm}, ${airChamber.packaging}`,
    maskType: CONVERT_FROM_INFARMED_TO_SERVER[chamberTypeId] || 0
  }
  nonMedicinePosologyAndGenericsInfo(spmsMedication, duration, `${instructions} durante ${extractNumberFromDuration(duration)} ${convertDurationUnit(duration)}`, false)
}

function reduceToKeyedObj (idKey = 'id', curr, entry) {
  curr[entry[idKey]] = entry
  return curr
}

const reduceToKeyedObjCopay = reduceToKeyedObj.bind(null, 'normId')

const MAX_EXCEPTION_C_DURATION = 1000

/**
 *
 * @param code {number}
 * @param posology {RealmPosology}
 * @return {number|undefined}
 */
function findExceptionTreatmentDuration (code, posology) {
  if (code !== Medicine.EXCEPTIONS.EXCEPTION_C) return undefined
  return posology.longTreatment ? MAX_EXCEPTION_C_DURATION : convertDurationToDays(posology.duration)
}

/**
 * @type {function(spmsMedication: SPMSMedication, realmLine: RealmPrescriptionLine, medicines: Object):void}
 * @param spmsMedication {SPMSMedication}
 * @param realmLine {RealmPrescriptionLine}
 * @param medicines {Object<string,{dci: {id: string, name: string}, medicine: Models.Medicine}>}
 * @param healthSystemDetails {RealmHealthSystemDetails}
 */
async function spmsConvertMedicine (spmsMedication, realmLine, medicines, healthSystemDetails, posologyDescription) {
  const {
    medicineId,
    packageId,
    cnpem,
    dciId,
    posology,
    requestedGeneric,
    justification
  } = realmLine.medicine
  const medicineData = medicines[medicineId]
  const packageData = medicineData.medicine.packagings.find(p => p.id === packageId)
  let medicationInfo
  if (requestedGeneric || medicineData.medicine.generic) {
    medicationInfo = {
      cnpem: cnpem,
      dciId: dciId,
      isNarcotic: medicineData.medicine.isNarcotic,
      pharmaceuticalFormId: medicineData.medicine.pharmForm.id,
      dosageDescription: medicineData.medicine.dosage,
      packageDescription: packageData?.description,
      longDuration: realmLine.validFor === '6M'
    }
  } else {
    medicationInfo = {
      registryNumber: packageData.registrationId,
      isNarcotic: medicineData.medicine.isNarcotic,
      longDuration: realmLine.validFor === '6M'
    }
  }
  spmsMedication.medicationInfo = medicationInfo
  if (healthSystemDetails.diplomas?.length > 0 && Object.keys(packageData.copayment.exceptionalRegime).length > 0) {
    const orderedCopays = Object.values(packageData.copayment.exceptionalRegime)
      .filter(cp => healthSystemDetails.diplomas.includes(`${cp.normId}`))
      .sort((cp1, cp2) => cp1.value - cp2.value)
      .reduce(reduceToKeyedObjCopay, {})
    const orderedKeys = Object.keys(orderedCopays)
    if (orderedKeys.length > 0) {
      spmsMedication.diplomaCodes = orderedKeys
    }
  }
  if (justification?.code) {
    spmsMedication.exceptionInfo = {
      exceptionCode: justification?.code,
      treatmentDuration: findExceptionTreatmentDuration(justification?.code, posology)
    }
  }
  nonMedicinePosologyAndGenericsInfo(spmsMedication, posology.duration, posologyDescription, posology.longTreatment, requestedGeneric)
}

/**
 *
 * @type {Map<string, function(spmsMedication: SPMSMedication, realmLine: RealmPrescriptionLine, medicines: Object,healthSystemDetails: RealmHealthSystemDetails, posologyDescription: string):void>}
 */
const REALM_TO_SPMS_LINE_CONVERTER = new Map([
  [TYPES_PRESCRIPTION.MANIPULATED, spmsConvertManipulated],
  [TYPES_PRESCRIPTION.OTHERS, spmsConvertOthers],
  [TYPES_PRESCRIPTION.GLUCOSE, spmsConvertGlucose],
  [TYPES_PRESCRIPTION.AIR_CHAMBER, spmsConvertAirChamber],
  [TYPES_PRESCRIPTION.MEDICINE, spmsConvertMedicine]
])

const CONVERT_TO_SERVER_REPRESENTATION = Object.freeze({
  JAU1: 'POSOLOGY',
  JAU2: 'STABILITY_CHRONIC',
  JAU3: 'PROLONGED_ABSENCE',
  JAU4: 'OTHER',
  UNKNOWN: 'UNKNOWN'
})

/**
 *
 * @param realmPrescription {RealmReduxPrescription}
 * @param medicines {Object}
 * @param patientDetails {ConverterPatientDetails}
 * @param prescriberDetails {ConverterPrescriberDetails}
 * @param posologies {string[]}
 * @returns {SPMSPrescription}
 */
export async function convertFromRealmToSPMS (realmPrescription, medicines, patientDetails, prescriberDetails, posologies) {
  /**
   *
   * @type {SPMSPrescription}
   */
  const spmsPrescription = {
    scriboId: realmPrescription.id,
    patient: {
      nhsId: realmPrescription.healthId,
      birthDate: patientDetails.birthDate,
      fullName: patientDetails.fullName,
      placeOfBirth: patientDetails.placeOfBirth,
      nationality: patientDetails.nationality,
      gender: patientDetails.gender,
      emails: patientDetails.email ? [patientDetails.email] : undefined
    },
    prescriptionLocale: prescriberDetails.prescriptionLocale,
    prescriptionLocaleName: prescriberDetails.prescriptionLocaleName,
    prescriber: {
      fullName: prescriberDetails.fullName,
      clinicalName: prescriberDetails.clinicalName,
      contact: prescriberDetails.contact,
      orderCode: prescriberDetails.orderCode,
      orderNumber: prescriberDetails.orderNumber,
      specialty: prescriberDetails.specialty
    },
    efr: {
      beneficiaryNumber: realmPrescription.healthSystemDetails.efr.beneficiaryNumber,
      entity: {
        domain: realmPrescription.healthSystemDetails.efr.entityInfo.domain,
        code: realmPrescription.healthSystemDetails.efr.entityInfo.code,
        country: realmPrescription.healthSystemDetails.efr.entityInfo.countryCode,
        designation: realmPrescription.healthSystemDetails.efr.entityInfo.name
      }
    }
  }
  if (Object.keys(realmPrescription.healthSystemDetails.recm ?? {}).length > 0) {
    spmsPrescription.recm = {
      code: realmPrescription.healthSystemDetails.recm.code,
      type: realmPrescription.healthSystemDetails.recm.type
    }
  }
  spmsPrescription.medications = await Promise.all(Object.values(realmPrescription.lines).map(async (line, index) => {
    /**
     *
     * @type {SPMSMedication}
     */
    const medicationLine = {
      quantity: line.quantity,
      typeMedication: line.type
    }
    if (line.healthSystemDetails.singleActCode) {
      medicationLine.extensionInfo = {
        justification: CONVERT_TO_SERVER_REPRESENTATION[line.healthSystemDetails.singleActCode] || CONVERT_TO_SERVER_REPRESENTATION.UNKNOWN,
        description: line.healthSystemDetails.singleActText,
        howLong: extractNumberFromDuration(line.healthSystemDetails.singleActDuration)
      }
    }
    const converterFunc = REALM_TO_SPMS_LINE_CONVERTER.get(line.type)
    if (converterFunc) {
      await converterFunc(medicationLine, line, medicines, realmPrescription.healthSystemDetails, posologies[index])
    }
    return medicationLine
  }))
  return spmsPrescription
}

/**
 *
 * @param prescription {RealmPrescription|RealmReduxPrescription}
 * @return {Promise<*>}
 */
export async function getMedicinesFromPrescription (prescription) {
  const mappedLines = (Array.isArray(prescription.lines) ? prescription.lines : Object.values(prescription.lines))
    .filter(l => l.type === TYPES_PRESCRIPTION.MEDICINE)
    .map(l => l.medicine)
    .map(m => ({
      medicine: m.medicineId,
      package: m.packageId,
      price: m.priceId
    }))
  return INFARMED.getMedicines(mappedLines)
}

/**
 *
 * @param prescription {RealmPrescription|RealmReduxPrescription}
 * @return {string[]}
 */
export function extractQrCodesUrls (prescription) {
  const lines = Array.isArray(prescription.lines) ? prescription.lines : Object.values(prescription.lines)
  return lines.map(l => l.healthSystemDetails.qrCode)
}

/**
 *
 * @param prescription {RealmPrescription|RealmReduxPrescription}
 * @return {string}
 */
export function extractTreatmentGuideUrl (prescription) {
  return prescription.healthSystemDetails.pdf
}

/**
 *
 * @param prescription {RealmPrescription|RealmReduxPrescription}
 * @return {string}
 */
export function extractPrescriptionNhsId (prescription) {
  return prescription.healthSystemDetails.prescriptionId
}

/**
 *
 * @param prescription {RealmPrescription}
 * @returns {boolean}
 */
export function hasPrescriptionExpired (prescription) {
  if (prescription.expired) return prescription.expired
  const latestExpiry = moment.max(convertLinesToList(prescription).map(line => moment(line.expiresAt)))
  return moment().isSameOrAfter(latestExpiry)
}
