import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useAuth0 } from '@auth0/auth0-react'

import './nav_bar.css'
import { extractPictureUserToken } from '../../common_functions'
import { useTranslate } from 'react-polyglot'
import OldSigningCardStatus from '../main_content/signing_card_status/signing_card_status'

const NavBar = React.memo(function NavBar ({
  token, professional
}) {
  const t = useTranslate()
  const pictureUrl = useMemo(() => extractPictureUserToken(professional, token), [token, professional])
  const { logout } = useAuth0()
  const logoutWithOptions = useCallback(() => {
    logout({ returnTo: `${window.location.origin}` })
  }, [logout])
  return <div className='d-flex justify-content-between align-content-center py-2 pr-2 ourNavbar'>
    <div className='d-flex justify-content-start align-content-center'>
      <OldSigningCardStatus />
    </div>
    <div className='d-flex justify-content-end align-content-center'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='name mr-2'>{professional?.clinicalName || token.username}</div>
        <div className='profile-photo-frame'>
          <img id='profile-photo' src={pictureUrl} alt='Profile' />
        </div>
      </div>
      <div className='dropdown'>
        <button className='btn dropdown-toggle pt-3 shadow-none' type='button' id='dropdownMenuButton'
                data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' />
        <div className='dropdown-menu dropdown-menu-right'>
          <a className='dropdown-item' rel='noreferrer' href='https://www.myscribo.com/privacy/' target='_blank'><span
            className='dropdown-item-text'>{t('dropdown.privacy')}</span></a>
          <a className='dropdown-item' rel='noreferrer' href='https://www.myscribo.com/terms/' target='_blank'><span
            className='dropdown-item-text'>{t('dropdown.terms')}</span></a>
          <a className='dropdown-item' rel='noreferrer' href='https://www.myscribo.com/faq/' target='_blank'><span
            className='dropdown-item-text'>{t('dropdown.faq')}</span></a>
          <div className='dropdown-divider' />
          <h6 className='dropdown-header dropdownHeader'>{t('dropdown.transfers')}</h6>
          <a className='dropdown-item' rel='noreferrer' href={t('dropdown.windowsDownload')} target='_blank'>
            <i className='dropdown-item-text fab fa-windows icon' />
            <span className='d-inline-block dropdown-item-text pl-0'>{t('dropdown.signer')} <sub>{t('dropdown.windowsVersion')}</sub></span>
          </a>
          <a className='dropdown-item' rel='noreferrer' href={t('dropdown.macDownload')} target='_blank'>
            <i className='dropdown-item-text fab fa-apple icon' />
            <span className='d-inline-block dropdown-item-text pl-0'>{t('dropdown.signer')} <sub>{t('dropdown.macVersion')}</sub></span>
          </a>
          <div className='dropdown-divider' />
          <button className='dropdown-item' type='button' onClick={logoutWithOptions}>
            <i className='dropdown-item-text fa fa-power-off fa-fw icon' />
            <span className='d-inline-block dropdown-item-text pl-0'>{t('dropdown.logout')}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
})

NavBar.propTypes = {
  token: PropTypes.object.isRequired,
  professional: PropTypes.shape({
    clinicalName: PropTypes.string.isRequired
  })
}

export default NavBar
