import lunr from 'lunr'

import { INFARMED } from '../constants'

import units from '../jsonSuggestions/units_raw.json'
import unitsRaw from '../jsonSuggestions/index_units.json'

function reducer (obj, curr) {
  obj[curr.id] = curr
  return obj
}

/**
 *
 * @param values {Object[]}
 * @param index {lunr.Index}
 * @param reportSize {boolean}
 * @returns {{VALUES: Object[], INDEX: lunr.Index, KEYED: Object<string,Object>}}
 */
function createObject (values, index, reportSize = false) {
  return {
    VALUES: values,
    KEYED: values.reduce(reducer, {}),
    INDEX: lunr.Index.load(index)
  }
}

INFARMED.ready.then(async () => {
  const glucose = await INFARMED.Glucose()
  INFARMED_CONSTANTS.GLUCOSE = createObject(glucose.VALUES, glucose.INDEX)
  const airChamber = await INFARMED.AirChamber()
  INFARMED_CONSTANTS.AIR_CHAMBER = createObject(airChamber.VALUES, airChamber.INDEX)
  const manipulated = await INFARMED.Manipulated()
  INFARMED_CONSTANTS.MANIPULATED = createObject(manipulated.VALUES, manipulated.INDEX)
})

/**
 * @typedef {Object} Manipulated
 * @property {string} field
 * @property {number} id
 * @property {number} pharmFormId
 * @property {number} registryNumber
 * @property {number} numberComponents
 * @property {string[]} idComponents
 */

/**
 *
 * @type {{MANIPULATED: {VALUES: Manipulated[], INDEX: lunr.Index, KEYED: Object<string|number, Manipulated>}, GLUCOSE: {VALUES, INDEX: lunr.Index, KEYED}, PHARMACEUTICAL_FORM: {VALUES, INDEX: lunr.Index, KEYED}, ADMIN_TYPE: {VALUES, INDEX: lunr.Index, KEYED}, AIR_CHAMBER: {VALUES, INDEX: lunr.Index, KEYED}, UNITS: {VALUES, INDEX: lunr.Index, KEYED}}}
 */
export const INFARMED_CONSTANTS = {
  UNITS: createObject(units, unitsRaw)
}
