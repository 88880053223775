import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import Prescription from '../../../models/prescription'

import './side_bar_item.css'

const SideBarItem = React.memo(function SideBarItem ({
  id, healthId, name, date, selectedItem, selectPrescription, showRemovePrescriptionModal
}) {
  const prescriptionDate = useMemo(() => new Date(date).toLocaleDateString(navigator.language), [date])

  const sizedName = useMemo(() => {
    if (!name) return null
    const fullPatientName = name.split(' ')
    const numberOfNames = fullPatientName.length
    if (numberOfNames > 4) {
      return fullPatientName.slice(0, 2).join(' ') + ' ' + fullPatientName.slice(numberOfNames - 2).join(' ')
    }
    return name
  }, [name])

  const itemClass = useMemo(() => {
    return id === selectedItem ? 'container-selected-item' : 'container-non-selected-item'
  }, [id, selectedItem])

  const showModal = useCallback(() => {
    showRemovePrescriptionModal(id)
    return false
  }, [id, showRemovePrescriptionModal])

  return <div
    className={`btn list-group-item list-group-item-action noOutline ${itemClass}`}
    onClick={selectPrescription}>
    <div className='container'>
      <div className='row align-items-center justify-content-between'>
        <div className='col-11'>
          {sizedName && <div
            className={`${id === selectedItem ? 'selectedItem' : ''} prescription-name font-weight-bold `}>{sizedName}</div>}
          <div className={`${id === selectedItem ? 'selectedItem' : ''} prescription-details font-weight-bold`}>Nº
            SNS: {healthId}</div>
          <div
            className={`${id === selectedItem ? 'selectedItem' : ''} prescription-details font-weight-bold`}>Data: {prescriptionDate}</div>
        </div>
        <div className='col-1 justify-content-center'>
          <button type='button' className='btn bg-transparent px-0' onClick={showModal}>
            <FontAwesomeIcon icon={faTrashAlt} color='red'  />
          </button>
        </div>
      </div>
    </div>
  </div>
})

SideBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  prescription: PropTypes.instanceOf(Prescription),
  selectedPrescription: PropTypes.func,
  selectedItem: PropTypes.string
}

export default SideBarItem
