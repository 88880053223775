import React, { useEffect, useMemo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { extractPictureUserToken } from '../../common_functions'
import { Redirect } from 'react-router'

const Login = React.memo(function Login ({ ignoreLoading }) {
  const { user, isAuthenticated, isLoading } = useAuth0()
  const userPicture = useMemo(() => user ? extractPictureUserToken(user) : null, [user])

  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    if (!ignoreLoading && !isLoading && isAuthenticated) {
      setShouldRedirect(true)
    }
  }, [isLoading, isAuthenticated, ignoreLoading])

  if (shouldRedirect) {
    return <Redirect to='/' />
  }

  return <div className='container-fluid w-100 h-100'>
    <div className='d-flex min-vh-100 flex-column justify-content-center align-content-center'>
      <div className='mx-auto'>
        {isLoading && <span className='text-center'>A carregar</span>}
        {isAuthenticated && <img id='profile-photo' src={userPicture} alt='Profile' />}
      </div>
      <div className='mx-auto'>
      </div>
    </div>
  </div>
})

export default Login
