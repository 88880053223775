import {
  AT_MEALS,
  CONFIGS,
  IN_TIME,
  PERIODICALLY,
  SOS,
  TYPES_PRESCRIPTION
} from '../constants'
import { v4 } from 'uuid'

const HASH_ALGORITHM = 'SHA-1'

/**
 * @typedef {Object} ServerMedicineUsage
 * @property CounterId {string}
 * @property UserId {string}
 * @property PrescriptionId {string}
 * @property LineId {string}
 * @property Active {boolean}
 */

/**
 * @typedef {Object} AppMedicineUsage
 * @property counterId {string}
 * @property userId {string}
 * @property prescriptionId {string}
 * @property lineId {string}
 * @property active {boolean}
 */

/**
 *
 * @param serverObj {ServerMedicineUsage}
 * @returns AppMedicineUsage
 */
function mapFromServerToApp (serverObj) {
  return Object.keys(serverObj).reduce((obj, key) => {
    const noCapitalKey = key.substr(0, 1).toLowerCase() + key.substr(1)
    obj[noCapitalKey] = serverObj[key]
    return obj
  }, {})
}

/**
 *
 * @param response {{status: number, result: ServerMedicineUsage[]}}
 * @return {Promise<AppMedicineUsage[]>}
 */
async function processResults (response) {
  if (!response || !response.status >= 400) {
    return []
  }
  const body = await response.json()
  return body.result.map(mapFromServerToApp)
}

/**
 *
 * @param accessToken {string}
 * @return {Promise<AppMedicineUsage[]>}
 */
export function getMostFrequent (accessToken) {
  const endpointUrl = new URL('/api/medicine_usage', CONFIGS.CURRENT.WEB_API)
  return fetch(endpointUrl.href, { headers: { Authorization: `Bearer ${accessToken}` } })
    .then(processResults)
}

const DATA_SEPARATOR = '_'

const PROPERTIES_SCHEMA_SPECIFIC_NAMES = Object.freeze({
  [PERIODICALLY]: ['numberOption', 'periodOption'],
  [SOS]: ['if', 'until'],
  [AT_MEALS]: ['mealsOptions', 'periodOptions'],
  [IN_TIME]: ['hours', 'daysOfWeek', 'daysOfMonth', 'period']
})

/**
 *
 * @param schemas {(PeriodicallySchema || SOSSchema || AtMealsSchema || InTimeSchema)[]}
 */
function generatePerSchemaValues (schemas) {
  return schemas.flatMap(schema => {
    const propertiesValues = [
      schema.useFraction, schema.numerator, schema.type, schema.period, schema.denominator, schema.numberPeriod,
      schema.adjustedDosage, schema.adjustedDuration
    ]
    PROPERTIES_SCHEMA_SPECIFIC_NAMES[schema.type].forEach(name => propertiesValues.push(schema[name]))
    return propertiesValues
  })
}

/**
 *
 * @param dataContainer {Object}
 * @param property {string}
 */
function getPropertyValue (dataContainer, property) {
  if (dataContainer === undefined || dataContainer === null) return dataContainer
  const dotIndex = property.indexOf('.')
  if (dotIndex === -1) return dataContainer[property]
  return getPropertyValue(dataContainer[property.substring(0, dotIndex)], property.substring(dotIndex + 1))
}

/**
 *
 * @param posology {MedicinePosology}
 * @return {string[]}
 */
function processPosology (posology) {
  const propertiesValues = [
    posology.instructions.patientInstructions,
    posology.scheduling.longDuration, posology.scheduling.numberPeriod, posology.scheduling.period,
    posology.dosage.denominator, posology.dosage.numerator, posology.dosage.unitsIndex, posology.dosage.useFraction
  ]
  propertiesValues.push(...generatePerSchemaValues(Object.values(posology.schemas)))
  return propertiesValues
}

/**
 *
 * @param basketItem {BasketMedicine}
 * @param prescription {RealmPrescription}
 * @param line {RealmPrescriptionLine}
 * @returns {string[]}
 */
function getIdForMedicine (basketItem, prescription, line) {
  const medicineDetails = [basketItem.medicine.packagingId, basketItem.requestedGeneric, basketItem.duration, basketItem.quantity, basketItem.justification, basketItem.singleActActive, basketItem.singleActCode, basketItem.singleActDuration, basketItem.singleActText]
  medicineDetails.push(...processPosology(basketItem.posology))
  return medicineDetails
}

/**
 *
 * @param properties {string[]}
 * @param basketItem {BasketAirChamber|BasketGlucose}
 * @param prescription {RealmPrescription}
 * @param line {RealmPrescriptionLine}
 * @return {string[]}
 */
function getIdForMedicalDevice (properties, basketItem, prescription, line) {
  const itemBound = getPropertyValue.bind(null, basketItem)
  return properties.map(itemBound)
}

const AIR_CHAMBER_PROPERTIES = Object.freeze(['quantity', 'instructions', 'startDate', 'durationUnit', 'durationValue', 'type', 'airChamber.id', 'airChamber.chamberTypeId', 'airChamber.registryNumber'])

const GLUCOSE_PROPERTIES = Object.freeze(['glucose.id', 'glucose.registryNumber', 'type', 'instructions', 'quantity', 'startDate', 'durationUnit', 'durationValue'])

/**
 *
 * @param properties {string[]}
 * @param specialProcessingKey {string}
 * @param basketItem {BasketManipulated|BasketOthers}
 * @param prescription {RealmPrescription}
 * @param line {RealmPrescriptionLine}
 * @return {string[]}
 */
function getIdForPersonalizedItem (properties, specialProcessingKey, basketItem, prescription, line) {
  const itemBound = getPropertyValue.bind(null, basketItem)
  const mappedProperties = properties.map(itemBound)
  Object.values(basketItem[specialProcessingKey]).forEach(d => mappedProperties.push(d.value, d.unit, d.label || d.text))
  return mappedProperties
}

const MANIPULATED_PROPERTIES = Object.freeze(['type', 'activeSubstances', 'packageId', 'pharmaceuticalForm', 'pharmaceuticalFormId', 'administrationType', 'administrationTypeId', 'posology', 'instructions', 'quantity', 'startDate', 'durationUnit', 'durationValue'])

const MANIPULATED_PROCESSING_KEY = 'dosage'

const OTHERS_PROPERTIES = Object.freeze(['type', 'pharmaceuticalForm', 'pharmaceuticalFormId', 'administrationType', 'administrationTypeId', 'posology', 'instructions', 'quantity', 'startDate', 'durationUnit', 'durationValue'])

const OTHERS_PROCESSING_KEY = 'components'

/**
 *
 * @type {Map<string, function((BasketMedicine|BasketAirChamber|BasketGlucose|BasketManipulated|BasketOthers), RealmPrescription, RealmPrescriptionLine): string[]>}
 */
const idMapper = new Map([
  [TYPES_PRESCRIPTION.MEDICINE, getIdForMedicine],
  [TYPES_PRESCRIPTION.AIR_CHAMBER, getIdForMedicalDevice.bind(null, AIR_CHAMBER_PROPERTIES)],
  [TYPES_PRESCRIPTION.GLUCOSE, getIdForMedicalDevice.bind(null, GLUCOSE_PROPERTIES)],
  [TYPES_PRESCRIPTION.MANIPULATED, getIdForPersonalizedItem.bind(null, MANIPULATED_PROPERTIES, MANIPULATED_PROCESSING_KEY)],
  [TYPES_PRESCRIPTION.OTHERS, getIdForPersonalizedItem.bind(null, OTHERS_PROPERTIES, OTHERS_PROCESSING_KEY)]
])

/**
 *
 * @param accessToken {string}
 * @param basketItems {(BasketAirChamber|BasketMedicine|BasketGlucose|BasketManipulated|BasketOthers)[]}
 * @param prescription {RealmPrescription}
 * @return {Promise<boolean>}
 */
export async function registerUsage (accessToken, basketItems, prescription) {
  /**
   *
   * @type {RealmPrescriptionLine[]}
   */
  const prescriptionLines = Array.isArray(prescription.lines) ? prescription.lines : Object.values(prescription.lines)
  const usage = await Promise.all(prescriptionLines.map(async (line, index) => {
    const mapper = idMapper.get(line.type)
    if (!mapper) {
      console.error(`Prescription ${prescription.id} on line ${index + 1} failed to process`)
    }
    let allData = mapper(basketItems[index], prescription, line)
    allData = allData.map(val => '' + val).join(DATA_SEPARATOR)
    const allDataEncoded = new TextEncoder().encode(allData)
    const hashBytes = await crypto.subtle.digest(HASH_ALGORITHM, allDataEncoded)
    const bytes = [...new Uint8Array(hashBytes)]
      .map(b => Number(b).toString(16).padStart(2, '0'))
      .join('')
    const counterId = v4({ random: new TextEncoder().encode(bytes).slice(0, 16) })
    return { CounterId: counterId, PrescriptionId: prescription.id, LineId: line.id }
  }))
  const endpointUrl = new URL('/api/medicine_usage', CONFIGS.CURRENT.WEB_API)
  const response = await fetch(endpointUrl.href, {
    method: 'POST',
    body: JSON.stringify({ usage }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.ok
}
