import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import AppReal from './appReal'

import './index.css'

import moment from 'moment'
import 'moment/locale/pt'
import { I18n } from 'react-polyglot'

import MESSAGES from './polyglot'
import 'popper.js'

const LOCALE = navigator.language[0] || navigator.language

moment.locale(LOCALE)

ReactDOM.render(
  <React.StrictMode>
    <I18n locale={LOCALE} messages={MESSAGES}>
      <AppReal />
    </I18n>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
