import React from 'react'

import { withAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'

import {
  addSignature,
  authenticatePrescriber,
  authorizedFetch,
  convertToActiveMedications,
  fetchRealmAPI,
  getPrescriptionHash,
  prescribe,
  registerActiveMedication,
  registerMedicationUsage,
  registerOnClinicalFile,
} from 'src/common_functions'
import {
  ACCESS_TOKEN_OPTIONS,
  CONFIGS,
  END_CERTIFICATE,
  INFARMED,
  NEEDED_PERMISSIONS,
  REQUEST_METHODS,
  SERVER_CHECK_LOCATION,
  SERVER_CHECK_VALUE,
  SERVER_SIGNING_CARD_STATUS_LOCATION,
  SHARED_WITH_TYPES,
  SIGNATURE_SERVER_PATH,
  SIGNING_CARD_STATUS,
  SIGNING_PROCESS
} from 'src/constants'

import NavBar from 'src/components/nav_bar/nav_bar'
import { SideBar } from 'src/components/side_bar/side_bar'
import NotAllowed from 'src/components/not_allowed/not_allowed'
import { MainContent } from 'src/components/main_content/main_content'

import './app.css'
import { v4 } from 'uuid'
import { eIDSignerContext } from '../../contexts/eIDSigner'

const CC_SIGNER_PORT_MINIMUM_RANGE = 9000
const CC_SIGNER_PORT_MAXIMUM_RANGE = 10000
const RETRY_SERVER_INTERVAL = 2000
const SET_READING_CARD_TIMEOUT = 3000
const MAXIMUM_SERVER_TRIES_COUNT = 2
const CHECK_SIGNING_CARD_STATUS_INTERVAL = 3000

let serverUrl
let serverTriesCount = 0
let testServerIntervalId
let readingCardTimeoutId
let ccSignerPort = CC_SIGNER_PORT_MINIMUM_RANGE

const PrescriptionContext = React.createContext()

/**
 *
 * @param {number} currentPortNumber
 */
function getNextOddPortNumber (currentPortNumber) {
  if (currentPortNumber % 2 === 0) {
    return currentPortNumber + 1
  }

  return currentPortNumber + 2
}

function resetServerPort () {
  ccSignerPort = CC_SIGNER_PORT_MINIMUM_RANGE
}

function incrementServerPort () {
  ccSignerPort = getNextOddPortNumber(ccSignerPort)
}

function getServerUrl () {
  let url
  if (serverUrl) {
    return Promise.resolve(serverUrl)
  }

  resetServerPort()
  return new Promise(async (resolve, reject) => {
    while (ccSignerPort <= CC_SIGNER_PORT_MAXIMUM_RANGE) {
      url = `${SIGNATURE_SERVER_PATH}:${ccSignerPort}${SERVER_CHECK_LOCATION}`
      serverUrl = await fetch(url)
        .then(async (response) => ({
          body: await response.json(),
          url: response.url
        }))
        .then(({ body, url }) => {
          if (body.checkValue === SERVER_CHECK_VALUE) {
            resolve(url)
            return Promise.resolve(url)
          }
          reject('Server hasn\'t passed the validation test')
        })
        .catch(_ => { })

      if (serverUrl) {
        break
      }

      incrementServerPort()
    }

    reject('Can\'t get response from the CCSigner server')
  })
}

function getServerCheckLocationLength () {
  return SERVER_CHECK_LOCATION.length
}

function getBaseUrl (url) {
  return url.slice(0, -getServerCheckLocationLength())
}

function handleNoResponseFromServer () {
  serverTriesCount += 1
}

function resetServerTriesCount () {
  serverTriesCount = 0
}

function handleStopTryingServer () {
  clearInterval(testServerIntervalId)
}

function handleServerAvailable (handleFunction, url) {
  handleStopTryingServer()
  handleFunction(url)
}

function handleServerNotAvailable (handleFunction) {
  handleStopTryingServer()
  handleFunction()
}

function verifyPermissions (auth0) {
  return auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
    .then(accessToken => {
      const { permissions } = jwtDecode(accessToken)
      return NEEDED_PERMISSIONS.every(permission => permissions.includes(permission))
    })
    .catch('Error while getting the access token')
}

async function getUnsignedPrescriptions (auth0) {
  try {
    const { prescriptions } = await fetchRealmAPI(auth0, '/prescriptions/unsigned')
    return prescriptions
  } catch (error) {
    console.error(error)
  }
}

async function getPrescriptionPossibleContacts (auth0, prescriptionId) {
  try {
    return await fetchRealmAPI(auth0, `/prescriptions/${prescriptionId}/contacts`)
  } catch (error) {
    console.error(error)
  }
  return false
}

async function removePendingPrescription (auth0, prescriptionId) {
  try {
    const response = await fetchRealmAPI(auth0, `/prescriptions/${prescriptionId}`, 'DELETE', null, {}, true)
    return response.status === 200
  } catch (e) {
    console.error(e)
  }
  return false
}

async function getProfessionalInfo (auth0) {
  try {
    const { professional } = await fetchRealmAPI(auth0, '/professionals')

    const {
      prescriptionLocaleCode,
      prescriptionLocaleName,
      clinicalName,
      professionalOrderId,
      occupation,
      occupationSpecialty,
      personalInfo: { givenName, familyName, fiscalId, picture },
      professionalPhone: { number: contact }
    } = professional

    return {
      prescriptionLocale: prescriptionLocaleCode,
      prescriptionLocaleName,
      fullName: `${givenName} ${familyName}`,
      clinicalName,
      orderCode: occupation,
      orderNumber: professionalOrderId,
      specialty: occupationSpecialty,
      contact,
      fiscalId,
      picture
    }
  } catch (error) {
    console.error(error)
  }
}

async function getPatientInfo (auth0, patientHealthId) {
  try {
    const path = new URL(`rnu/${patientHealthId}`, CONFIGS.CURRENT.CMD_PEM_SERVER_DOMAIN)
    const { patient: { identification } } = await auth0.getAccessTokenSilently(ACCESS_TOKEN_OPTIONS)
      .then(accessToken => authorizedFetch(accessToken, path, REQUEST_METHODS.GET))

    const splitName = identification.fullName.split(' ')
    let initials
    if (splitName.length > 1) {
      initials = `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(0)}`
    } else {
      initials = splitName[0].charAt(0)
    }

    const picture = identification.picture || `https://i1.wp.com/cdn.auth0.com/avatars/${initials.toLowerCase()}.png?ssl=1`

    return {
      nhsId: identification.number,
      fullName: identification.fullName,
      birthDate: identification.birthDate,
      gender: identification.gender,
      placeOfBirth: identification.birthCountryIsoCode,
      nationality: identification.birthCountryIsoCode,
      picture: picture
    }
  } catch (error) {
    console.error(error)
  }
}

class App extends React.PureComponent {
  static contextType = eIDSignerContext

  constructor (props) {
    super(props)
    this.state = {
      serverUrl: null,
      signingCardStatus: null,
      unsignedPrescriptions: [],
      userNotAllowed: false
    }
  }

  handleShouldRetryServer () {
    resetServerTriesCount()
    this.setup()
  }

  setServerNotAvailable () {
    this.setState({ signingCardStatus: SIGNING_CARD_STATUS.SERVER_UNAVAILABLE })
  }

  setReadingCard () {
    readingCardTimeoutId = null
    this.setState({ signingCardStatus: SIGNING_CARD_STATUS.READING_CARD })
  }

  setServerUrl (url) {
    this.setState({ serverUrl: getBaseUrl(url) })
    setInterval(this.getSigningCardStatus.bind(this), CHECK_SIGNING_CARD_STATUS_INTERVAL)
  }

  handleSigningCardStatusResponse (responseBody) {
    if (readingCardTimeoutId) {
      clearTimeout(readingCardTimeoutId)
    }
    this.setState({ signingCardStatus: responseBody.operationCode })
  }

  getSigningCardStatus () {
    readingCardTimeoutId = setTimeout(this.setReadingCard.bind(this), SET_READING_CARD_TIMEOUT)
    fetch(`${this.state.serverUrl}${SERVER_SIGNING_CARD_STATUS_LOCATION}`)
      .then(response => response.json())
      .then(body => this.handleSigningCardStatusResponse(body))
      .catch(_ => this.setState({ signingCardStatus: SIGNING_CARD_STATUS.SERVER_UNREACHABLE }))
  }

  setup () {
    this.setState({ signingCardStatus: SIGNING_CARD_STATUS.CONTACTING_SERVER })
    testServerIntervalId = setInterval(this.tryServer.bind(this), RETRY_SERVER_INTERVAL)
  }

  tryServer () {
    if (serverTriesCount >= MAXIMUM_SERVER_TRIES_COUNT) {
      handleServerNotAvailable(this.setServerNotAvailable.bind(this))
    } else {
      getServerUrl()
        .then(serverUrl => handleServerAvailable(this.setServerUrl.bind(this), serverUrl))
        .catch(handleNoResponseFromServer)
    }
  }

  setUnsignedPrescriptions () {
    const unsignedPrescriptions = []
    const { auth0 } = this.props
    getUnsignedPrescriptions(auth0)
      .then(prescriptions => {

        const healthIdsToLoad = prescriptions.reduce((set, currPrescr) => {
          set.add(currPrescr.healthId)
          return set
        }, new Set())

        Promise.all([...healthIdsToLoad].map(id => getPatientInfo(auth0, id)))
          .then(patientsInfo => INFARMED.ready.then(ready => {
            if (!ready) throw new Error('Infarmed failed to become ready')
            this.setState({
              patients: patientsInfo.reduce((obj, curr) => {
                obj[curr.nhsId] = curr
                return obj
              }, {}),
              unsignedPrescriptions: prescriptions
            })
          }))
      })
      .catch(error => console.error('Error while fetching all unsigned prescriptions', 'Error: ' + error))

    this.setState({ unsignedPrescriptions: unsignedPrescriptions })
  }

  componentDidMount () {
    const { auth0 } = this.props
    verifyPermissions(auth0)
      .then(hasPermission => {
        if (hasPermission) {
          // this.setup()
          getProfessionalInfo(auth0)
            .then(professionalInfo => this.setState({ professionalInfo }))
          this.setUnsignedPrescriptions()
        } else {
          this.setState({ userNotAllowed: true })
        }
      })
  }

  setPrescription = (prescription) => {
    this.setState({ prescription })
  }

  addEmails = async (spmsPrescription, basketItems, emails, callWithState) => {
    callWithState(SIGNING_PROCESS.ADDING_SHARING)
    let updatedPrescription
    if (this.state.prescription.sharedWith?.length === 0) {
      const realmEmails = emails.map(email => ({ id: v4(), type: SHARED_WITH_TYPES.EMAIL, value: email }))
      const requestBody = { prescriptionId: this.state.prescription.id, sharedWith: realmEmails }
      const response = await fetchRealmAPI(this.props.auth0, '/prescriptions/sharedWith', 'POST', requestBody)
      updatedPrescription = response.prescription
      this.setPrescription({ ...this.state.prescription, ...updatedPrescription })
    }
    callWithState(SIGNING_PROCESS.AUTHENTICATING_PRESCRIBER)
    const result = await this.context.getAuthenticationCertificates(`${ccSignerPort}`)
    if (!result || result.code === 0) {
      callWithState(SIGNING_PROCESS.ERROR_AUTHENTICATING_PRESCRIBER)
      return
    }
    const { authCertificates } = result
    const authResult = await authenticatePrescriber(this.props.auth0, authCertificates, this.state.professionalInfo)
    if (!authResult || authResult.errorCode) {
      callWithState(SIGNING_PROCESS.ERROR_AUTHENTICATING_PRESCRIBER)
      return
    }
    const { token } = authResult
    callWithState(SIGNING_PROCESS.HASHING_PRESCRIPTION)
    const hashResult = await getPrescriptionHash(this.props.auth0, spmsPrescription)
    const { info: { id, hash } } = hashResult
    callWithState(SIGNING_PROCESS.WAITING_SIGNING)
    const signingResult = await this.context.sign(hash)
    const { certificates, signed, canceledByUser } = signingResult
    if (canceledByUser) {
      callWithState(SIGNING_PROCESS.ERROR_SIGNING_CANCELED_BY_USER)
      return
    } else if (!certificates || !signed) {
      callWithState(SIGNING_PROCESS.ERROR_WAITING_SIGNING)
      return
    }
    const certificatesForServer = certificates.split(END_CERTIFICATE).slice(0, 2).map(entry => `${entry.replaceAll('\n', '')}${END_CERTIFICATE}`).join('')
    callWithState(SIGNING_PROCESS.ADDING_DIGITAL_SIGNATURE)
    await addSignature(this.props.auth0, id, signed[0], certificatesForServer)
    callWithState(SIGNING_PROCESS.PRESCRIBING)
    const prescribingResult = await prescribe(this.props.auth0, id, token, emails)
    if (Object.keys(prescribingResult).length > 0) {
      callWithState(SIGNING_PROCESS.ERROR_PRESCRIBING, prescribingResult)
      return
    }
    callWithState(SIGNING_PROCESS.PRESCRIBED)
    registerOnClinicalFile(this.props.auth0, this.state.prescription.id)
    registerMedicationUsage(this.props.auth0, this.state.prescription, basketItems)
    const activeMedications = await convertToActiveMedications(this.state.prescription, basketItems)
    registerActiveMedication(this.props.auth0, activeMedications, this.state.prescription.healthId)
    this.showPrescriptionSuccess()
    setTimeout(async () => {
      this.setState({
        prescription: null,
        unsignedPrescriptions: this.state.unsignedPrescriptions.filter(unp => unp.id !== spmsPrescription.scriboId)
      })
      this.hidePrescriptionSuccess()
    }, 3500)
  }

  showPrescriptionRemoved = () => {
    window.$('#deletionSuccessful').modal('show')
  }

  showPrescriptionSuccess = () => {
    window.$('#staticBackdrop').modal('show')
  }

  hidePrescriptionSuccess = () => {
    window.$('#staticBackdrop').modal('hide')
  }

  showRemovePrescriptionModal = (prescriptionId) => {
    this.setState({ prescriptionRemovalId: prescriptionId })
    window.$('#exampleModal').modal('show')
  }

  hideRemovePrescriptionModal = () => {
    this.setState({ prescriptionRemovalId: null })
    window.$('#exampleModal').modal('hide')
  }

  removeAndHidePrescription = () => {
    console.log(this.state)
    if (this.state.prescriptionRemovalId) {
      const prescriptionId = this.state.prescriptionRemovalId
      removePendingPrescription(this.props.auth0, prescriptionId)
        .then(success => {
          if (!success) return
          setTimeout(() => {
            this.hideRemovePrescriptionModal()
            this.setState({
              prescription: null,
              unsignedPrescriptions: this.state.unsignedPrescriptions.filter(unp => unp.id !== prescriptionId)
            })
            this.showPrescriptionRemoved()
          }, 2000)
        })
    }
  }

  getContactsForPrescription = (prescriptionId) => {
    return getPrescriptionPossibleContacts(this.props.auth0, prescriptionId)
  }

  render () {
    const {
      serverUrl,
      userNotAllowed,
      unsignedPrescriptions,
      patients,
      professionalInfo
    } = this.state

    const { cardStatus } = this.context

    const { auth0: { isAuthenticated, user } } = this.props

    return userNotAllowed ? <NotAllowed />
      : <div className='d-flex h-100 w-100'>
        <div className='w-25 h-100'>
          <SideBar
            prescriptions={unsignedPrescriptions} patients={patients}
            selectedPrescription={this.setPrescription}
            showRemovePrescriptionModal={this.showRemovePrescriptionModal}
          />
          <div className='modal fade' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel'
               aria-hidden='true'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalLabel'>Remover Prescrição</h5>
                  <button type='button' className='close' aria-label='Close' onClick={this.hideRemovePrescriptionModal}>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  Deseja remover esta prescrição?
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={this.hideRemovePrescriptionModal}>Não
                  </button>
                  <button type='button' className='btn btn-danger' onClick={this.removeAndHidePrescription}>Sim</button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal fade' id='staticBackdrop' data-backdrop='static' data-keyboard='false' tabIndex='-1'
               aria-labelledby='staticBackdropLabel' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabel'>Medicação Prescrita</h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  Prescrição realizada com sucesso
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-success' data-dismiss='modal'>Close</button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal fade' id='deletionSuccessful' data-backdrop='static' data-keyboard='false' tabIndex='-1'
               aria-labelledby='staticBackdropLabel' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabel'>Prescrição Removida</h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  Prescrição removida com sucesso
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='w-75 h-100'
          id='page-content-wrapper'>
          <NavBar token={user} professional={professionalInfo}
                  name={isAuthenticated && user.name}
          />
          <MainContent
            prescription={this.state.prescription} patients={patients}
            cardStatus={cardStatus}
            serverUrl={serverUrl}
            professionalInfo={professionalInfo}
            addEmailsAndEmitPrescription={this.addEmails}
            getContactsForPrescription={this.getContactsForPrescription}
          />
        </div>
      </div>
  }
}

export { PrescriptionContext }
export default withAuth0(App)
